/* -------------------------------------------------------------
//  Helper
// -----------------------------------------------------------*/

// Positions
.sticky-top {
  position: sticky;
  top: 0;
}


// Margins
.margin-bottom-1 {
  margin-bottom: 1rem;
}

// Alignment
.center {
  text-align: center;
}
.left {
  text-align: left;
}
.right {
  text-emphasis: right;
}

// Layout
.flex, .flexbox {
  display: flex;
}

// Text Modifiers
.uppercase {
  text-transform: uppercase;
}

// Displays
.display-block {
  display: block !important;
}
.invisible {
  display: none;
}
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

// Fonts
.font-size-xxlarge {
  font-size: to-rem($font-xxlarge-font-size);
}
.font-size-xlarge {
  font-size: to-rem($font-xlarge-font-size);
}
.font-size-large {
  font-size: to-rem($font-large-font-size);
}
.font-size-medium {
  font-size: to-rem($font-medium-font-size);
}
.font-size-small {
  font-size: to-rem($font-small-font-size);
}
.font-size-xsmall {
  font-size: to-rem($font-xsmall-font-size);
}
.font-size-xxsmall {
font-size: to-rem($font-xxsmall-font-size);
}

// Spacings
.space-xxsmall, .size-xxsmall {
  margin-right: to-rem($sizes-xxsmall);
  margin-left: to-rem($sizes-xxsmall);
}
.space-xsmall, .size-xsmall {
  margin-right: to-rem($sizes-xsmall);
  margin-left: to-rem($sizes-xsmall);
}
.space-small, .size-small {
  margin-right: to-rem($sizes-small);
  margin-left: to-rem($sizes-small);
}
.space-medium, .size-medium {
  margin-right: to-rem($sizes-medium);
  margin-left: to-rem($sizes-medium);
}
.space-large, .size-large {
  margin-right: to-rem($sizes-large);
  margin-left: to-rem($sizes-large);
}
.space-xlarge, .size-xlarge {
  margin-right: to-rem($sizes-xlarge);
  margin-left: to-rem($sizes-xlarge);
}
.space-lxxarge, .size-xxlarge {
  margin-right: to-rem($sizes-xxlarge);
  margin-left: to-rem($sizes-xxlarge);
}
.space-section, .size-section {
  margin-right: to-rem($sizes-section);
  margin-left: to-rem($sizes-section);
}