footer {
  background-color: $footer-background-color;
  color: $footer-text-color;
  padding: to-rem($sizes-section) 0 to-rem($sizes-section);
}

/* Footernavigation */
.footer {
  //  
  &__list {
    //
    & > i {
      font-size: to-rem($font-medium-line-height);
    }
    //
  }

  &__item {
    display: flex;
    align-items: center; 
    //
  }

  &__link {
    // 
    &:hover {
      //
    }
  }
}