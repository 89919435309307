/* -------------------------------------------------------------
//  Iconfont
// -----------------------------------------------------------*/

@font-face {
  font-family: 'iconfont';
  src: 
    url('../fonts/iconfont.ttf') format('truetype'),
    url('../fonts/iconfont.woff') format('woff');
  font-display: block;
  font-style: normal;
  font-weight: normal;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'iconfont' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  line-height: 1;
  text-transform: none;


  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arrow-down:before {
  content: "\e900";
}
.icon-arrow-left:before {
  content: "\e901";
}
.icon-arrow-right:before {
  content: "\e902";
}
.icon-arrow-up:before {
  content: "\e903";
}
.icon-calendar:before {
  content: "\e904";
}
.icon-chat:before {
  content: "\e905";
}
.icon-chevron-down:before {
  content: "\e906";
}
.icon-chevron-left:before {
  content: "\e907";
}
.icon-chevron-right:before {
  content: "\e908";
}
.icon-chevron-up:before {
  content: "\e909";
}
.icon-close:before {
  content: "\e90a";
}
.icon-delete:before {
  content: "\e90b";
}
.icon-document:before {
  content: "\e90c";
}
.icon-download:before {
  content: "\e90d";
}
.icon-edit:before {
  content: "\e90e";
}
.icon-envelope:before {
  content: "\e90f";
}
.icon-external-link:before {
  content: "\e910";
}
.icon-facebook:before {
  content: "\e911";
}
.icon-filter-1:before {
  content: "\e912";
}
.icon-filter:before {
  content: "\e913";
}
.icon-google:before {
  content: "\e914";
}
.icon-hide:before {
  content: "\e915";
}
.icon-home:before {
  content: "\e916";
}
.icon-image:before {
  content: "\e917";
}
.icon-instagram:before {
  content: "\e918";
}
.icon-like:before {
  content: "\e919";
}
.icon-linkedin:before {
  content: "\e91a";
}
.icon-location-pin:before {
  content: "\e91b";
}
.icon-lock:before {
  content: "\e91c";
}
.icon-menu:before {
  content: "\e91d";
}
.icon-minus:before {
  content: "\e91e";
}
.icon-phone:before {
  content: "\e91f";
}
.icon-plus:before {
  content: "\e920";
}
.icon-profile:before {
  content: "\e921";
}
.icon-search:before {
  content: "\e922";
}
.icon-settings:before {
  content: "\e923";
}
.icon-shopping-cart:before {
  content: "\e924";
}
.icon-show:before {
  content: "\e925";
}
.icon-twitter:before {
  content: "\e926";
}
.icon-unlock:before {
  content: "\e927";
}
.icon-video:before {
  content: "\e928";
}
.icon-youTube:before {
  content: "\e929";
}

