/* -------------------------------------------------------------
//  Section
// -----------------------------------------------------------*/
// .section {
//   border-top: 1px solid transparent;
//   border-bottom: 1px solid transparent;

//   &::before,
//   &::after {
//     content: '';
//     display: block;
//   }

//   &::before {
//     margin-bottom: to-rem($section-margin);
//   }

//   &::after {
//     margin-top: to-rem($section-margin);
//   }

//   &--light {
//     background-color: $section-background-light;
//   }
//   &--dark {
//     background-color: $section-background-dark;
//   }
// }

/**
 * WEBSITE: https://themefisher.com
 * TWITTER: https://twitter.com/themefisher
 * FACEBOOK: https://www.facebook.com/themefisher
 * GITHUB: https://github.com/themefisher/
 */

 .section.first {
  padding-top: 2rem;
  padding-bottom: 0;

  .ce_image img {
    height: 429px;
    object-fit: cover;
  }
}

.projekt_text p strong {
  color: $color-primary !important;
}

.projekt_text ul li {
  color: $color-black !important;
}
 /*!
  * Bootstrap v5.0.2 (https://getbootstrap.com/)
  * Copyright 2011-2021 The Bootstrap Authors
  * Copyright 2011-2021 Twitter, Inc.
  * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
  */
 :root {
   --bs-blue: #0d6efd;
   --bs-indigo: #6610f2;
   --bs-purple: #6f42c1;
   --bs-pink: #d63384;
   --bs-red: #dc3545;
   --bs-orange: #fd7e14;
   --bs-yellow: #ffc107;
   --bs-green: #198754;
   --bs-teal: #20c997;
   --bs-cyan: #0dcaf0;
   --bs-white: #fff;
   --bs-gray: #6c757d;
   --bs-gray-dark: #343a40;
   --bs-primary: #0d6efd;
   --bs-secondary: #6c757d;
   --bs-success: #198754;
   --bs-info: #0dcaf0;
   --bs-warning: #ffc107;
   --bs-danger: #dc3545;
   --bs-light: #EAEAEA;
   --bs-dark: #233242;
   --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
   --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
   --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
 }
 
 *,
 *::before,
 *::after {
   box-sizing: border-box;
 }
 
 @media (prefers-reduced-motion: no-preference) {
   :root {
     scroll-behavior: smooth;
   }
 }
 
 body {
   margin: 0;
   font-family: var(--bs-font-sans-serif);
   font-size: 1rem;
   font-weight: 400;
   line-height: 1.5;
   color: #fff;
   background-color: #fff;
   -webkit-text-size-adjust: 100%;
   -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
 }
 
 hr {
   margin: 1rem 0;
   color: inherit;
   background-color: currentColor;
   border: 0;
   opacity: 0.25;
 }
 
 hr:not([size]) {
   height: 1px;
 }
 



 
 
 .card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  padding: 1rem 1rem;
}

.card-title {
  margin-bottom: 0.5rem;
}

.card-subtitle {
  margin-top: -0.25rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}
.card-link + .card-link {
  margin-left: 1rem;
}

.card-header {
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-footer {
  padding: 0.5rem 1rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}
.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
  margin-right: -0.5rem;
  margin-bottom: -0.5rem;
  margin-left: -0.5rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.5rem;
  margin-left: -0.5rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1rem;
  border-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-group > .card {
  margin-bottom: 0.75rem;
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
.card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
.card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
.card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
.card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

 
 .breadcrumb {
   display: flex;
   flex-wrap: wrap;
   padding: 0 0;
   margin-bottom: 1rem;
   list-style: none;
 }
 
 .breadcrumb-item + .breadcrumb-item {
   padding-left: 0.5rem;
 }
 .breadcrumb-item + .breadcrumb-item::before {
   float: left;
   padding-right: 0.5rem;
   color: #6c757d;
   content: var(--bs-breadcrumb-divider, "/") /* rtl: var(--bs-breadcrumb-divider, "/") */;
 }
 .breadcrumb-item.active {
   color: #6c757d;
 }
 
 .pagination {
   display: flex;
   padding-left: 0;
   list-style: none;
 }
 
 .modal {
   position: fixed;
   top: 0;
   left: 0;
   z-index: 1055;
   display: none;
   width: 100%;
   height: 100%;
   overflow-x: hidden;
   overflow-y: auto;
   outline: 0;
 }
 
 .modal-dialog {
   position: relative;
   width: auto;
   margin: 0.5rem;
   pointer-events: none;
 }
 .modal.fade .modal-dialog {
   transition: transform 0.3s ease-out;
   transform: translate(0, -50px);
 }
 @media (prefers-reduced-motion: reduce) {
   .modal.fade .modal-dialog {
     transition: none;
   }
 }
 .modal.show .modal-dialog {
   transform: none;
 }
 .modal.modal-static .modal-dialog {
   transform: scale(1.02);
 }
 
 .modal-dialog-scrollable {
   height: calc(100% - 1rem);
 }
 .modal-dialog-scrollable .modal-content {
   max-height: 100%;
   overflow: hidden;
 }
 .modal-dialog-scrollable .modal-body {
   overflow-y: auto;
 }
 
 .modal-dialog-centered {
   display: flex;
   align-items: center;
   min-height: calc(100% - 1rem);
 }
 
 .modal-content {
   position: relative;
   display: flex;
   flex-direction: column;
   width: 100%;
   pointer-events: auto;
   background-color: #fff;
   background-clip: padding-box;
   border: 1px solid rgba(0, 0, 0, 0.2);
   border-radius: 0.3rem;
   outline: 0;
 }
 
 .modal-backdrop {
   position: fixed;
   top: 0;
   left: 0;
   z-index: 1050;
   width: 100vw;
   height: 100vh;
   background-color: #000;
 }
 .modal-backdrop.fade {
   opacity: 0;
 }
 .modal-backdrop.show {
   opacity: 0.5;
 }
 
 .modal-header {
   display: flex;
   flex-shrink: 0;
   align-items: center;
   justify-content: space-between;
   padding: 1rem 1rem;
   border-bottom: 1px solid #ACB9C4;
   border-top-left-radius: calc(0.3rem - 1px);
   border-top-right-radius: calc(0.3rem - 1px);
 }
 .modal-header .btn-close {
   padding: 0.5rem 0.5rem;
   margin: -0.5rem -0.5rem -0.5rem auto;
 }
 
 .modal-title {
   margin-bottom: 0;
   line-height: 1.5;
 }
 
 .modal-body {
   position: relative;
   flex: 1 1 auto;
   padding: 1rem;
 }
 
 .modal-footer {
   display: flex;
   flex-wrap: wrap;
   flex-shrink: 0;
   align-items: center;
   justify-content: flex-end;
   padding: 0.75rem;
   border-top: 1px solid #ACB9C4;
   border-bottom-right-radius: calc(0.3rem - 1px);
   border-bottom-left-radius: calc(0.3rem - 1px);
 }
 .modal-footer > * {
   margin: 0.25rem;
 }
 
 @media (min-width: 576px) {
   .modal-dialog {
     max-width: 500px;
     margin: 1.75rem auto;
   }
   .modal-dialog-scrollable {
     height: calc(100% - 3.5rem);
   }
   .modal-dialog-centered {
     min-height: calc(100% - 3.5rem);
   }
   .modal-sm {
     max-width: 300px;
   }
 }
 @media (min-width: 992px) {
   .modal-lg,
 .modal-xl {
     max-width: 800px;
   }
 }
 @media (min-width: 1200px) {
   .modal-xl {
     max-width: 1140px;
   }
 }
 .modal-fullscreen {
   width: 100vw;
   max-width: none;
   height: 100%;
   margin: 0;
 }
 .modal-fullscreen .modal-content {
   height: 100%;
   border: 0;
   border-radius: 0;
 }
 .modal-fullscreen .modal-header {
   border-radius: 0;
 }
 .modal-fullscreen .modal-body {
   overflow-y: auto;
 }
 .modal-fullscreen .modal-footer {
   border-radius: 0;
 }
 
 @media (max-width: 575.98px) {
   .modal-fullscreen-sm-down {
     width: 100vw;
     max-width: none;
     height: 100%;
     margin: 0;
   }
   .modal-fullscreen-sm-down .modal-content {
     height: 100%;
     border: 0;
     border-radius: 0;
   }
   .modal-fullscreen-sm-down .modal-header {
     border-radius: 0;
   }
   .modal-fullscreen-sm-down .modal-body {
     overflow-y: auto;
   }
   .modal-fullscreen-sm-down .modal-footer {
     border-radius: 0;
   }
 }
 @media (max-width: 767.98px) {
   .modal-fullscreen-md-down {
     width: 100vw;
     max-width: none;
     height: 100%;
     margin: 0;
   }
   .modal-fullscreen-md-down .modal-content {
     height: 100%;
     border: 0;
     border-radius: 0;
   }
   .modal-fullscreen-md-down .modal-header {
     border-radius: 0;
   }
   .modal-fullscreen-md-down .modal-body {
     overflow-y: auto;
   }
   .modal-fullscreen-md-down .modal-footer {
     border-radius: 0;
   }
 }
 @media (max-width: 991.98px) {
   .modal-fullscreen-lg-down {
     width: 100vw;
     max-width: none;
     height: 100%;
     margin: 0;
   }
   .modal-fullscreen-lg-down .modal-content {
     height: 100%;
     border: 0;
     border-radius: 0;
   }
   .modal-fullscreen-lg-down .modal-header {
     border-radius: 0;
   }
   .modal-fullscreen-lg-down .modal-body {
     overflow-y: auto;
   }
   .modal-fullscreen-lg-down .modal-footer {
     border-radius: 0;
   }
 }
 @media (max-width: 1199.98px) {
   .modal-fullscreen-xl-down {
     width: 100vw;
     max-width: none;
     height: 100%;
     margin: 0;
   }
   .modal-fullscreen-xl-down .modal-content {
     height: 100%;
     border: 0;
     border-radius: 0;
   }
   .modal-fullscreen-xl-down .modal-header {
     border-radius: 0;
   }
   .modal-fullscreen-xl-down .modal-body {
     overflow-y: auto;
   }
   .modal-fullscreen-xl-down .modal-footer {
     border-radius: 0;
   }
 }
 @media (max-width: 1399.98px) {
   .modal-fullscreen-xxl-down {
     width: 100vw;
     max-width: none;
     height: 100%;
     margin: 0;
   }
   .modal-fullscreen-xxl-down .modal-content {
     height: 100%;
     border: 0;
     border-radius: 0;
   }
   .modal-fullscreen-xxl-down .modal-header {
     border-radius: 0;
   }
   .modal-fullscreen-xxl-down .modal-body {
     overflow-y: auto;
   }
   .modal-fullscreen-xxl-down .modal-footer {
     border-radius: 0;
   }
 }
 .clearfix::after {
   display: block;
   clear: both;
   content: "";
 }
 
 .link-primary {
   color: #0d6efd;
 }
 .link-primary:hover, .link-primary:focus {
   color: #0a58ca;
 }
 
 .link-secondary {
   color: #6c757d;
 }
 .link-secondary:hover, .link-secondary:focus {
   color: #565e64;
 }
 
 .link-success {
   color: #198754;
 }
 .link-success:hover, .link-success:focus {
   color: #146c43;
 }
 
 .link-info {
   color: #0dcaf0;
 }
 .link-info:hover, .link-info:focus {
   color: #3dd5f3;
 }
 
 .link-warning {
   color: #ffc107;
 }
 .link-warning:hover, .link-warning:focus {
   color: #ffcd39;
 }
 
 .link-danger {
   color: #dc3545;
 }
 .link-danger:hover, .link-danger:focus {
   color: #b02a37;
 }
 
 .link-light {
   color: #EAEAEA;
 }
 .link-light:hover, .link-light:focus {
   color: #eeeeee;
 }
 
 .link-dark {
   color: #233242;
 }
 .link-dark:hover, .link-dark:focus {
   color: #1c2835;
 }
 
 .ratio {
   position: relative;
   width: 100%;
 }
 .ratio::before {
   display: block;
   padding-top: var(--bs-aspect-ratio);
   content: "";
 }
 .ratio > * {
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
 }
 
 .ratio-1x1 {
   --bs-aspect-ratio: 100%;
 }
 
 .ratio-4x3 {
   --bs-aspect-ratio: 75%;
 }
 
 .ratio-16x9 {
   --bs-aspect-ratio: 56.25%;
 }
 
 .ratio-21x9 {
   --bs-aspect-ratio: 42.8571428571%;
 }
 
 .fixed-top {
   position: fixed;
   top: 0;
   right: 0;
   left: 0;
   z-index: 1030;
 }
 
 .fixed-bottom {
   position: fixed;
   right: 0;
   bottom: 0;
   left: 0;
   z-index: 1030;
 }
 
 .sticky-top {
   position: -webkit-sticky;
   position: sticky;
   top: 0;
   z-index: 1020;
 }
 
 @media (min-width: 576px) {
   .sticky-sm-top {
     position: -webkit-sticky;
     position: sticky;
     top: 0;
     z-index: 1020;
   }
 }
 @media (min-width: 768px) {
   .sticky-md-top {
     position: -webkit-sticky;
     position: sticky;
     top: 0;
     z-index: 1020;
   }
 }
 @media (min-width: 992px) {
   .sticky-lg-top {
     position: -webkit-sticky;
     position: sticky;
     top: 0;
     z-index: 1020;
   }
 }
 @media (min-width: 1200px) {
   .sticky-xl-top {
     position: -webkit-sticky;
     position: sticky;
     top: 0;
     z-index: 1020;
   }
 }
 @media (min-width: 1400px) {
   .sticky-xxl-top {
     position: -webkit-sticky;
     position: sticky;
     top: 0;
     z-index: 1020;
   }
 }
 .visually-hidden,
 .visually-hidden-focusable:not(:focus):not(:focus-within) {
   position: absolute !important;
   width: 1px !important;
   height: 1px !important;
   padding: 0 !important;
   margin: -1px !important;
   overflow: hidden !important;
   clip: rect(0, 0, 0, 0) !important;
   white-space: nowrap !important;
   border: 0 !important;
 }
 
 .stretched-link::after {
   position: absolute;
   top: 0;
   right: 0;
   bottom: 0;
   left: 0;
   z-index: 1;
   content: "";
 }
 
 .text-truncate {
   overflow: hidden;
   text-overflow: ellipsis;
   white-space: nowrap;
 }
 
 .align-baseline {
   vertical-align: baseline !important;
 }
 
 .align-top {
   vertical-align: top !important;
 }
 
 .align-middle {
   vertical-align: middle !important;
 }
 
 .align-bottom {
   vertical-align: bottom !important;
 }
 
 .align-text-bottom {
   vertical-align: text-bottom !important;
 }
 
 .align-text-top {
   vertical-align: text-top !important;
 }
 
 .float-start {
   float: left !important;
 }
 
 .float-end {
   float: right !important;
 }
 
 .float-none {
   float: none !important;
 }
 
 .opacity-0 {
   opacity: 0 !important;
 }
 
 .opacity-25 {
   opacity: 0.25 !important;
 }
 
 .opacity-50 {
   opacity: 0.5 !important;
 }
 
 .opacity-75 {
   opacity: 0.75 !important;
 }
 
 .opacity-100 {
   opacity: 1 !important;
 }
 
 .overflow-auto {
   overflow: auto !important;
 }
 
 .overflow-hidden {
   overflow: hidden !important;
 }
 
 .overflow-visible {
   overflow: visible !important;
 }
 
 .overflow-scroll {
   overflow: scroll !important;
 }
 
 .d-inline {
   display: inline !important;
 }
 
 .d-inline-block {
   display: inline-block !important;
 }
 
 .d-block {
   display: block !important;
 }
 
 .d-grid {
   display: grid !important;
 }
 
 .d-table {
   display: table !important;
 }
 
 .d-table-row {
   display: table-row !important;
 }
 
 .d-table-cell {
   display: table-cell !important;
 }
 
 .d-flex {
   display: flex !important;
 }
 
 .d-inline-flex {
   display: inline-flex !important;
 }
 
 .d-none {
   display: none !important;
 }
 
 .shadow {
   box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
 }
 
 .shadow-sm {
   box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
 }
 
 .shadow-lg {
   box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
 }
 
 .shadow-none {
   box-shadow: none !important;
 }
 
 .position-static {
   position: static !important;
 }
 
 .position-relative {
   position: relative !important;
 }
 
 .position-absolute {
   position: absolute !important;
 }
 
 .position-fixed {
   position: fixed !important;
 }
 
 .position-sticky {
   position: -webkit-sticky !important;
   position: sticky !important;
 }
 
 .top-0 {
   top: 0 !important;
 }
 
 .top-50 {
   top: 50% !important;
 }
 
 .top-100 {
   top: 100% !important;
 }
 
 .bottom-0 {
   bottom: 0 !important;
 }
 
 .bottom-50 {
   bottom: 50% !important;
 }
 
 .bottom-100 {
   bottom: 100% !important;
 }
 
 .start-0 {
   left: 0 !important;
 }
 
 .start-50 {
   left: 50% !important;
 }
 
 .start-100 {
   left: 100% !important;
 }
 
 .end-0 {
   right: 0 !important;
 }
 
 .end-50 {
   right: 50% !important;
 }
 
 .end-100 {
   right: 100% !important;
 }
 
 .translate-middle {
   transform: translate(-50%, -50%) !important;
 }
 
 .translate-middle-x {
   transform: translateX(-50%) !important;
 }
 
 .translate-middle-y {
   transform: translateY(-50%) !important;
 }
 
 .border {
   border: 1px solid #ACB9C4 !important;
 }
 
 .border-0 {
   border: 0 !important;
 }
 
 .border-top {
   border-top: 1px solid #ACB9C4 !important;
 }
 
 .border-top-0 {
   border-top: 0 !important;
 }
 
 .border-end {
   border-right: 1px solid #ACB9C4 !important;
 }
 
 .border-end-0 {
   border-right: 0 !important;
 }
 
 .border-bottom {
   border-bottom: 1px solid #ACB9C4 !important;
 }
 
 .border-bottom-0 {
   border-bottom: 0 !important;
 }
 
 .border-start {
   border-left: 1px solid #ACB9C4 !important;
 }
 
 .border-start-0 {
   border-left: 0 !important;
 }
 
 .border-primary {
   border-color: #0d6efd !important;
 }
 
 .border-secondary {
   border-color: #6c757d !important;
 }
 
 .border-success {
   border-color: #198754 !important;
 }
 
 .border-info {
   border-color: #0dcaf0 !important;
 }
 
 .border-warning {
   border-color: #ffc107 !important;
 }
 
 .border-danger {
   border-color: #dc3545 !important;
 }
 
 .border-light {
   border-color: #EAEAEA !important;
 }
 
 .border-dark {
   border-color: #233242 !important;
 }
 
 .border-white {
   border-color: #fff !important;
 }
 
 .border-1 {
   border-width: 1px !important;
 }
 
 .border-2 {
   border-width: 2px !important;
 }
 
 .border-3 {
   border-width: 3px !important;
 }
 
 .border-4 {
   border-width: 4px !important;
 }
 
 .border-5 {
   border-width: 5px !important;
 }
 
 .w-25 {
   width: 25% !important;
 }
 
 .w-50 {
   width: 50% !important;
 }
 
 .w-75 {
   width: 75% !important;
 }
 
 .w-100 {
   width: 100% !important;
 }
 
 .w-auto {
   width: auto !important;
 }
 
 .mw-100 {
   max-width: 100% !important;
 }
 
 .vw-100 {
   width: 100vw !important;
 }
 
 .min-vw-100 {
   min-width: 100vw !important;
 }
 
 .h-25 {
   height: 25% !important;
 }
 
 .h-50 {
   height: 50% !important;
 }
 
 .h-75 {
   height: 75% !important;
 }
 
 .h-100 {
   height: 100% !important;
 }
 
 .h-auto {
   height: auto !important;
 }
 
 .mh-100 {
   max-height: 100% !important;
 }
 
 .vh-100 {
   height: 100vh !important;
 }
 
 .min-vh-100 {
   min-height: 100vh !important;
 }
 
 .flex-fill {
   flex: 1 1 auto !important;
 }
 
 .flex-row {
   flex-direction: row !important;
 }
 
 .flex-column {
   flex-direction: column !important;
 }
 
 .flex-row-reverse {
   flex-direction: row-reverse !important;
 }
 
 .flex-column-reverse {
   flex-direction: column-reverse !important;
 }
 
 .flex-grow-0 {
   flex-grow: 0 !important;
 }
 
 .flex-grow-1 {
   flex-grow: 1 !important;
 }
 
 .flex-shrink-0 {
   flex-shrink: 0 !important;
 }
 
 .flex-shrink-1 {
   flex-shrink: 1 !important;
 }
 
 .flex-wrap {
   flex-wrap: wrap !important;
 }
 
 .flex-nowrap {
   flex-wrap: nowrap !important;
 }
 
 .flex-wrap-reverse {
   flex-wrap: wrap-reverse !important;
 }
 
 .gap-0 {
   gap: 0 !important;
 }
 
 .gap-1 {
   gap: 0.25rem !important;
 }
 
 .gap-2 {
   gap: 0.5rem !important;
 }
 
 .gap-3 {
   gap: 1rem !important;
 }
 
 .gap-4 {
   gap: 1.5rem !important;
 }
 
 .gap-5 {
   gap: 3rem !important;
 }
 
 .justify-content-start {
   justify-content: flex-start !important;
 }
 
 .justify-content-end {
   justify-content: flex-end !important;
 }
 
 .justify-content-center {
   justify-content: center !important;
 }
 
 .justify-content-between {
   justify-content: space-between !important;
 }
 
 .justify-content-around {
   justify-content: space-around !important;
 }
 
 .justify-content-evenly {
   justify-content: space-evenly !important;
 }
 
 .align-items-start {
   align-items: flex-start !important;
 }
 
 .align-items-end {
   align-items: flex-end !important;
 }
 
 .align-items-center {
   align-items: center !important;
 }
 
 .align-items-baseline {
   align-items: baseline !important;
 }
 
 .align-items-stretch {
   align-items: stretch !important;
 }
 
 .align-content-start {
   align-content: flex-start !important;
 }
 
 .align-content-end {
   align-content: flex-end !important;
 }
 
 .align-content-center {
   align-content: center !important;
 }
 
 .align-content-between {
   align-content: space-between !important;
 }
 
 .align-content-around {
   align-content: space-around !important;
 }
 
 .align-content-stretch {
   align-content: stretch !important;
 }
 
 .align-self-auto {
   align-self: auto !important;
 }
 
 .align-self-start {
   align-self: flex-start !important;
 }
 
 .align-self-end {
   align-self: flex-end !important;
 }
 
 .align-self-center {
   align-self: center !important;
 }
 
 .align-self-baseline {
   align-self: baseline !important;
 }
 
 .align-self-stretch {
   align-self: stretch !important;
 }
 
 .order-first {
   order: -1 !important;
 }
 
 .order-0 {
   order: 0 !important;
 }
 
 .order-1 {
   order: 1 !important;
 }
 
 .order-2 {
   order: 2 !important;
 }
 
 .order-3 {
   order: 3 !important;
 }
 
 .order-4 {
   order: 4 !important;
 }
 
 .order-5 {
   order: 5 !important;
 }
 
 .order-last {
   order: 6 !important;
 }
 
 .m-0 {
   margin: 0 !important;
 }
 
 .m-1 {
   margin: 0.25rem !important;
 }
 
 .m-2 {
   margin: 0.5rem !important;
 }
 
 .m-3 {
   margin: 1rem !important;
 }
 
 .m-4 {
   margin: 1.5rem !important;
 }
 
 .m-5 {
   margin: 3rem !important;
 }
 
 .m-auto {
   margin: auto !important;
 }
 
 .mx-0 {
   margin-right: 0 !important;
   margin-left: 0 !important;
 }
 
 .mx-1 {
   margin-right: 0.25rem !important;
   margin-left: 0.25rem !important;
 }
 
 .mx-2 {
   margin-right: 0.5rem !important;
   margin-left: 0.5rem !important;
 }
 
 .mx-3 {
   margin-right: 1rem !important;
   margin-left: 1rem !important;
 }
 
 .mx-4 {
   margin-right: 1.5rem !important;
   margin-left: 1.5rem !important;
 }
 
 .mx-5 {
   margin-right: 3rem !important;
   margin-left: 3rem !important;
 }
 
 .mx-auto {
   margin-right: auto !important;
   margin-left: auto !important;
 }
 
 .my-0 {
   margin-top: 0 !important;
   margin-bottom: 0 !important;
 }
 
 .my-1 {
   margin-top: 0.25rem !important;
   margin-bottom: 0.25rem !important;
 }
 
 .my-2 {
   margin-top: 0.5rem !important;
   margin-bottom: 0.5rem !important;
 }
 
 .my-3 {
   margin-top: 1rem !important;
   margin-bottom: 1rem !important;
 }
 
 .my-4 {
   margin-top: 1.5rem !important;
   margin-bottom: 1.5rem !important;
 }
 
 .my-5 {
   margin-top: 3rem !important;
   margin-bottom: 3rem !important;
 }
 
 .my-auto {
   margin-top: auto !important;
   margin-bottom: auto !important;
 }
 
 .mt-0 {
   margin-top: 0 !important;
 }
 
 .mt-1 {
   margin-top: 0.25rem !important;
 }
 
 .mt-2 {
   margin-top: 0.5rem !important;
 }
 
 .mt-3 {
   margin-top: 1rem !important;
 }
 
 .mt-4 {
   margin-top: 1.5rem !important;
 }
 
 .mt-5 {
   margin-top: 3rem !important;
 }
 
 .mt-auto {
   margin-top: auto !important;
 }
 
 .me-0 {
   margin-right: 0 !important;
 }
 
 .me-1 {
   margin-right: 0.25rem !important;
 }
 
 .me-2 {
   margin-right: 0.5rem !important;
 }
 
 .me-3 {
   margin-right: 1rem !important;
 }
 
 .me-4 {
   margin-right: 1.5rem !important;
 }
 
 .me-5 {
   margin-right: 3rem !important;
 }
 
 .me-auto {
   margin-right: auto !important;
 }
 
 .mb-0 {
   margin-bottom: 0 !important;
 }
 
 .mb-1 {
   margin-bottom: 0.25rem !important;
 }
 
 .mb-2 {
   margin-bottom: 0.5rem !important;
 }
 
 .mb-3 {
   margin-bottom: 1rem !important;
 }
 
 .mb-4 {
   margin-bottom: 1.5rem !important;
 }
 
 .mb-5 {
   margin-bottom: 3rem !important;
 }
 
 .mb-auto {
   margin-bottom: auto !important;
 }
 
 .ms-0 {
   margin-left: 0 !important;
 }
 
 .ms-1 {
   margin-left: 0.25rem !important;
 }
 
 .ms-2 {
   margin-left: 0.5rem !important;
 }
 
 .ms-3 {
   margin-left: 1rem !important;
 }
 
 .ms-4 {
   margin-left: 1.5rem !important;
 }
 
 .ms-5 {
   margin-left: 3rem !important;
 }
 
 .ms-auto {
   margin-left: auto !important;
 }
 
 .p-0 {
   padding: 0 !important;
 }
 
 .p-1 {
   padding: 0.25rem !important;
 }
 
 .p-2 {
   padding: 0.5rem !important;
 }
 
 .p-3 {
   padding: 1rem !important;
 }
 
 .p-4 {
   padding: 1.5rem !important;
 }
 
 .p-5 {
   padding: 3rem !important;
 }
 
 .px-0 {
   padding-right: 0 !important;
   padding-left: 0 !important;
 }
 
 .px-1 {
   padding-right: 0.25rem !important;
   padding-left: 0.25rem !important;
 }
 
 .px-2 {
   padding-right: 0.5rem !important;
   padding-left: 0.5rem !important;
 }
 
 .px-3 {
   padding-right: 1rem !important;
   padding-left: 1rem !important;
 }
 
 .px-4 {
   padding-right: 1.5rem !important;
   padding-left: 1.5rem !important;
 }
 
 .px-5 {
   padding-right: 3rem !important;
   padding-left: 3rem !important;
 }
 
 .py-0 {
   padding-top: 0 !important;
   padding-bottom: 0 !important;
 }
 
 .py-1 {
   padding-top: 0.25rem !important;
   padding-bottom: 0.25rem !important;
 }
 
 .py-2 {
   padding-top: 0.5rem !important;
   padding-bottom: 0.5rem !important;
 }
 
 .py-3 {
   padding-top: 1rem !important;
   padding-bottom: 1rem !important;
 }
 
 .py-4 {
   padding-top: 1.5rem !important;
   padding-bottom: 1.5rem !important;
 }
 
 .py-5 {
   padding-top: 3rem !important;
   padding-bottom: 3rem !important;
 }
 
 .pt-0 {
   padding-top: 0 !important;
 }
 
 .pt-1 {
   padding-top: 0.25rem !important;
 }
 
 .pt-2 {
   padding-top: 0.5rem !important;
 }
 
 .pt-3 {
   padding-top: 1rem !important;
 }
 
 .pt-4 {
   padding-top: 1.5rem !important;
 }
 
 .pt-5 {
   padding-top: 3rem !important;
 }
 
 .pe-0 {
   padding-right: 0 !important;
 }
 
 .pe-1 {
   padding-right: 0.25rem !important;
 }
 
 .pe-2 {
   padding-right: 0.5rem !important;
 }
 
 .pe-3 {
   padding-right: 1rem !important;
 }
 
 .pe-4 {
   padding-right: 1.5rem !important;
 }
 
 .pe-5 {
   padding-right: 3rem !important;
 }
 
 .pb-0 {
   padding-bottom: 0 !important;
 }
 
 .pb-1 {
   padding-bottom: 0.25rem !important;
 }
 
 .pb-2 {
   padding-bottom: 0.5rem !important;
 }
 
 .pb-3 {
   padding-bottom: 1rem !important;
 }
 
 .pb-4 {
   padding-bottom: 1.5rem !important;
 }
 
 .pb-5 {
   padding-bottom: 3rem !important;
 }
 
 .ps-0 {
   padding-left: 0 !important;
 }
 
 .ps-1 {
   padding-left: 0.25rem !important;
 }
 
 .ps-2 {
   padding-left: 0.5rem !important;
 }
 
 .ps-3 {
   padding-left: 1rem !important;
 }
 
 .ps-4 {
   padding-left: 1.5rem !important;
 }
 
 .ps-5 {
   padding-left: 3rem !important;
 }
 
 .font-monospace {
   font-family: var(--bs-font-monospace) !important;
 }
 
 .fs-1 {
   font-size: calc(1.375rem + 1.5vw) !important;
 }
 
 .fs-2 {
   font-size: calc(1.325rem + 0.9vw) !important;
 }
 
 .fs-3 {
   font-size: calc(1.3rem + 0.6vw) !important;
 }
 
 .fs-4 {
   font-size: calc(1.275rem + 0.3vw) !important;
 }
 
 .fs-5 {
   font-size: 1.25rem !important;
 }
 
 .fs-6 {
   font-size: 1rem !important;
 }
 
 .fst-italic {
   font-style: italic !important;
 }
 
 .fst-normal {
   font-style: normal !important;
 }
 
 .fw-light {
   font-weight: 300 !important;
 }
 
 .fw-lighter {
   font-weight: lighter !important;
 }
 
 .fw-normal {
   font-weight: 400 !important;
 }
 
 .fw-bold {
   font-weight: 700 !important;
 }
 
 .fw-bolder {
   font-weight: bolder !important;
 }
 
 .lh-1 {
   line-height: 1 !important;
 }
 
 .lh-sm {
   line-height: 1.25 !important;
 }
 
 .lh-base {
   line-height: 1.5 !important;
 }
 
 .lh-lg {
   line-height: 2 !important;
 }
 
 .text-start {
   text-align: left !important;
 }
 
 .text-end {
   text-align: right !important;
 }
 
 .text-center {
   text-align: center !important;
 }
 
 .text-decoration-none {
   text-decoration: none !important;
 }
 
 .text-decoration-underline {
   text-decoration: underline !important;
 }
 
 .text-decoration-line-through {
   text-decoration: line-through !important;
 }
 
 .text-lowercase {
   text-transform: lowercase !important;
 }
 
 .text-uppercase {
   text-transform: uppercase !important;
 }
 
 .text-capitalize {
   text-transform: capitalize !important;
 }
 
 .text-wrap {
   white-space: normal !important;
 }
 
 .text-nowrap {
   white-space: nowrap !important;
 }
 
 /* rtl:begin:remove */
 .text-break {
   word-wrap: break-word !important;
   word-break: break-word !important;
 }
 
 /* rtl:end:remove */
 .text-primary {
   color: #0d6efd !important;
 }
 
 .text-secondary {
   color: $color-black !important;
 }
 
 .text-success {
   color: #198754 !important;
 }
 
 .text-info {
   color: #0dcaf0 !important;
 }
 
 .text-warning {
   color: #ffc107 !important;
 }
 
 .text-danger {
   color: #dc3545 !important;
 }
 
 .text-light {
   color: #EAEAEA !important;
 }
 
 .text-dark {
   color: #233242 !important;
 }
 
 .text-white {
   color: #fff !important;
 }
 
 .text-body {
   color: #fff !important;
 }
 
 .text-muted {
   color: #6c757d !important;
 }
 
 .text-black-50 {
   color: rgba(0, 0, 0, 0.5) !important;
 }
 
 .text-white-50 {
   color: rgba(255, 255, 255, 0.5) !important;
 }
 
 .text-reset {
   color: inherit !important;
 }
 
 .bg-primary {
   background-color: #0d6efd !important;
 }
 
 .bg-secondary {
   background-color: #6c757d !important;
 }
 
 .bg-success {
   background-color: #198754 !important;
 }
 
 .bg-info {
   background-color: #0dcaf0 !important;
 }
 
 .bg-warning {
   background-color: #ffc107 !important;
 }
 
 .bg-danger {
   background-color: #dc3545 !important;
 }
 
 .bg-light {
   background-color: #EAEAEA !important;
 }
 
 .bg-dark {
   background-color: #233242 !important;
 }
 
 .bg-body {
   background-color: #fff !important;
 }
 
 .bg-white {
   background-color: #fff !important;
 }
 
 .bg-transparent {
   background-color: transparent !important;
 }
 
 .bg-gradient {
   background-image: var(--bs-gradient) !important;
 }
 
 .user-select-all {
   -webkit-user-select: all !important;
      -moz-user-select: all !important;
           user-select: all !important;
 }
 
 .user-select-auto {
   -webkit-user-select: auto !important;
      -moz-user-select: auto !important;
           user-select: auto !important;
 }
 
 .user-select-none {
   -webkit-user-select: none !important;
      -moz-user-select: none !important;
           user-select: none !important;
 }
 
 .pe-none {
   pointer-events: none !important;
 }
 
 .pe-auto {
   pointer-events: auto !important;
 }
 
 .rounded {
   border-radius: 0.25rem !important;
 }
 
 .rounded-0 {
   border-radius: 0 !important;
 }
 
 .rounded-1 {
   border-radius: 0.2rem !important;
 }
 
 .rounded-2 {
   border-radius: 0.25rem !important;
 }
 
 .rounded-3 {
   border-radius: 0.3rem !important;
 }
 
 .rounded-circle {
   border-radius: 50% !important;
 }
 
 .rounded-pill {
   border-radius: 50rem !important;
 }
 
 .rounded-top {
   border-top-left-radius: 0.25rem !important;
   border-top-right-radius: 0.25rem !important;
 }
 
 .rounded-end {
   border-top-right-radius: 0.25rem !important;
   border-bottom-right-radius: 0.25rem !important;
 }
 
 .rounded-bottom {
   border-bottom-right-radius: 0.25rem !important;
   border-bottom-left-radius: 0.25rem !important;
 }
 
 .rounded-start {
   border-bottom-left-radius: 0.25rem !important;
   border-top-left-radius: 0.25rem !important;
 }
 
 .visible {
   visibility: visible !important;
 }
 
 .invisible {
   visibility: hidden !important;
 }
 
 @media (min-width: 576px) {
   .float-sm-start {
     float: left !important;
   }
   .float-sm-end {
     float: right !important;
   }
   .float-sm-none {
     float: none !important;
   }
   .d-sm-inline {
     display: inline !important;
   }
   .d-sm-inline-block {
     display: inline-block !important;
   }
   .d-sm-block {
     display: block !important;
   }
   .d-sm-grid {
     display: grid !important;
   }
   .d-sm-table {
     display: table !important;
   }
   .d-sm-table-row {
     display: table-row !important;
   }
   .d-sm-table-cell {
     display: table-cell !important;
   }
   .d-sm-flex {
     display: flex !important;
   }
   .d-sm-inline-flex {
     display: inline-flex !important;
   }
   .d-sm-none {
     display: none !important;
   }
   .flex-sm-fill {
     flex: 1 1 auto !important;
   }
   .flex-sm-row {
     flex-direction: row !important;
   }
   .flex-sm-column {
     flex-direction: column !important;
   }
   .flex-sm-row-reverse {
     flex-direction: row-reverse !important;
   }
   .flex-sm-column-reverse {
     flex-direction: column-reverse !important;
   }
   .flex-sm-grow-0 {
     flex-grow: 0 !important;
   }
   .flex-sm-grow-1 {
     flex-grow: 1 !important;
   }
   .flex-sm-shrink-0 {
     flex-shrink: 0 !important;
   }
   .flex-sm-shrink-1 {
     flex-shrink: 1 !important;
   }
   .flex-sm-wrap {
     flex-wrap: wrap !important;
   }
   .flex-sm-nowrap {
     flex-wrap: nowrap !important;
   }
   .flex-sm-wrap-reverse {
     flex-wrap: wrap-reverse !important;
   }
   .gap-sm-0 {
     gap: 0 !important;
   }
   .gap-sm-1 {
     gap: 0.25rem !important;
   }
   .gap-sm-2 {
     gap: 0.5rem !important;
   }
   .gap-sm-3 {
     gap: 1rem !important;
   }
   .gap-sm-4 {
     gap: 1.5rem !important;
   }
   .gap-sm-5 {
     gap: 3rem !important;
   }
   .justify-content-sm-start {
     justify-content: flex-start !important;
   }
   .justify-content-sm-end {
     justify-content: flex-end !important;
   }
   .justify-content-sm-center {
     justify-content: center !important;
   }
   .justify-content-sm-between {
     justify-content: space-between !important;
   }
   .justify-content-sm-around {
     justify-content: space-around !important;
   }
   .justify-content-sm-evenly {
     justify-content: space-evenly !important;
   }
   .align-items-sm-start {
     align-items: flex-start !important;
   }
   .align-items-sm-end {
     align-items: flex-end !important;
   }
   .align-items-sm-center {
     align-items: center !important;
   }
   .align-items-sm-baseline {
     align-items: baseline !important;
   }
   .align-items-sm-stretch {
     align-items: stretch !important;
   }
   .align-content-sm-start {
     align-content: flex-start !important;
   }
   .align-content-sm-end {
     align-content: flex-end !important;
   }
   .align-content-sm-center {
     align-content: center !important;
   }
   .align-content-sm-between {
     align-content: space-between !important;
   }
   .align-content-sm-around {
     align-content: space-around !important;
   }
   .align-content-sm-stretch {
     align-content: stretch !important;
   }
   .align-self-sm-auto {
     align-self: auto !important;
   }
   .align-self-sm-start {
     align-self: flex-start !important;
   }
   .align-self-sm-end {
     align-self: flex-end !important;
   }
   .align-self-sm-center {
     align-self: center !important;
   }
   .align-self-sm-baseline {
     align-self: baseline !important;
   }
   .align-self-sm-stretch {
     align-self: stretch !important;
   }
   .order-sm-first {
     order: -1 !important;
   }
   .order-sm-0 {
     order: 0 !important;
   }
   .order-sm-1 {
     order: 1 !important;
   }
   .order-sm-2 {
     order: 2 !important;
   }
   .order-sm-3 {
     order: 3 !important;
   }
   .order-sm-4 {
     order: 4 !important;
   }
   .order-sm-5 {
     order: 5 !important;
   }
   .order-sm-last {
     order: 6 !important;
   }
   .m-sm-0 {
     margin: 0 !important;
   }
   .m-sm-1 {
     margin: 0.25rem !important;
   }
   .m-sm-2 {
     margin: 0.5rem !important;
   }
   .m-sm-3 {
     margin: 1rem !important;
   }
   .m-sm-4 {
     margin: 1.5rem !important;
   }
   .m-sm-5 {
     margin: 3rem !important;
   }
   .m-sm-auto {
     margin: auto !important;
   }
   .mx-sm-0 {
     margin-right: 0 !important;
     margin-left: 0 !important;
   }
   .mx-sm-1 {
     margin-right: 0.25rem !important;
     margin-left: 0.25rem !important;
   }
   .mx-sm-2 {
     margin-right: 0.5rem !important;
     margin-left: 0.5rem !important;
   }
   .mx-sm-3 {
     margin-right: 1rem !important;
     margin-left: 1rem !important;
   }
   .mx-sm-4 {
     margin-right: 1.5rem !important;
     margin-left: 1.5rem !important;
   }
   .mx-sm-5 {
     margin-right: 3rem !important;
     margin-left: 3rem !important;
   }
   .mx-sm-auto {
     margin-right: auto !important;
     margin-left: auto !important;
   }
   .my-sm-0 {
     margin-top: 0 !important;
     margin-bottom: 0 !important;
   }
   .my-sm-1 {
     margin-top: 0.25rem !important;
     margin-bottom: 0.25rem !important;
   }
   .my-sm-2 {
     margin-top: 0.5rem !important;
     margin-bottom: 0.5rem !important;
   }
   .my-sm-3 {
     margin-top: 1rem !important;
     margin-bottom: 1rem !important;
   }
   .my-sm-4 {
     margin-top: 1.5rem !important;
     margin-bottom: 1.5rem !important;
   }
   .my-sm-5 {
     margin-top: 3rem !important;
     margin-bottom: 3rem !important;
   }
   .my-sm-auto {
     margin-top: auto !important;
     margin-bottom: auto !important;
   }
   .mt-sm-0 {
     margin-top: 0 !important;
   }
   .mt-sm-1 {
     margin-top: 0.25rem !important;
   }
   .mt-sm-2 {
     margin-top: 0.5rem !important;
   }
   .mt-sm-3 {
     margin-top: 1rem !important;
   }
   .mt-sm-4 {
     margin-top: 1.5rem !important;
   }
   .mt-sm-5 {
     margin-top: 3rem !important;
   }
   .mt-sm-auto {
     margin-top: auto !important;
   }
   .me-sm-0 {
     margin-right: 0 !important;
   }
   .me-sm-1 {
     margin-right: 0.25rem !important;
   }
   .me-sm-2 {
     margin-right: 0.5rem !important;
   }
   .me-sm-3 {
     margin-right: 1rem !important;
   }
   .me-sm-4 {
     margin-right: 1.5rem !important;
   }
   .me-sm-5 {
     margin-right: 3rem !important;
   }
   .me-sm-auto {
     margin-right: auto !important;
   }
   .mb-sm-0 {
     margin-bottom: 0 !important;
   }
   .mb-sm-1 {
     margin-bottom: 0.25rem !important;
   }
   .mb-sm-2 {
     margin-bottom: 0.5rem !important;
   }
   .mb-sm-3 {
     margin-bottom: 1rem !important;
   }
   .mb-sm-4 {
     margin-bottom: 1.5rem !important;
   }
   .mb-sm-5 {
     margin-bottom: 3rem !important;
   }
   .mb-sm-auto {
     margin-bottom: auto !important;
   }
   .ms-sm-0 {
     margin-left: 0 !important;
   }
   .ms-sm-1 {
     margin-left: 0.25rem !important;
   }
   .ms-sm-2 {
     margin-left: 0.5rem !important;
   }
   .ms-sm-3 {
     margin-left: 1rem !important;
   }
   .ms-sm-4 {
     margin-left: 1.5rem !important;
   }
   .ms-sm-5 {
     margin-left: 3rem !important;
   }
   .ms-sm-auto {
     margin-left: auto !important;
   }
   .p-sm-0 {
     padding: 0 !important;
   }
   .p-sm-1 {
     padding: 0.25rem !important;
   }
   .p-sm-2 {
     padding: 0.5rem !important;
   }
   .p-sm-3 {
     padding: 1rem !important;
   }
   .p-sm-4 {
     padding: 1.5rem !important;
   }
   .p-sm-5 {
     padding: 3rem !important;
   }
   .px-sm-0 {
     padding-right: 0 !important;
     padding-left: 0 !important;
   }
   .px-sm-1 {
     padding-right: 0.25rem !important;
     padding-left: 0.25rem !important;
   }
   .px-sm-2 {
     padding-right: 0.5rem !important;
     padding-left: 0.5rem !important;
   }
   .px-sm-3 {
     padding-right: 1rem !important;
     padding-left: 1rem !important;
   }
   .px-sm-4 {
     padding-right: 1.5rem !important;
     padding-left: 1.5rem !important;
   }
   .px-sm-5 {
     padding-right: 3rem !important;
     padding-left: 3rem !important;
   }
   .py-sm-0 {
     padding-top: 0 !important;
     padding-bottom: 0 !important;
   }
   .py-sm-1 {
     padding-top: 0.25rem !important;
     padding-bottom: 0.25rem !important;
   }
   .py-sm-2 {
     padding-top: 0.5rem !important;
     padding-bottom: 0.5rem !important;
   }
   .py-sm-3 {
     padding-top: 1rem !important;
     padding-bottom: 1rem !important;
   }
   .py-sm-4 {
     padding-top: 1.5rem !important;
     padding-bottom: 1.5rem !important;
   }
   .py-sm-5 {
     padding-top: 3rem !important;
     padding-bottom: 3rem !important;
   }
   .pt-sm-0 {
     padding-top: 0 !important;
   }
   .pt-sm-1 {
     padding-top: 0.25rem !important;
   }
   .pt-sm-2 {
     padding-top: 0.5rem !important;
   }
   .pt-sm-3 {
     padding-top: 1rem !important;
   }
   .pt-sm-4 {
     padding-top: 1.5rem !important;
   }
   .pt-sm-5 {
     padding-top: 3rem !important;
   }
   .pe-sm-0 {
     padding-right: 0 !important;
   }
   .pe-sm-1 {
     padding-right: 0.25rem !important;
   }
   .pe-sm-2 {
     padding-right: 0.5rem !important;
   }
   .pe-sm-3 {
     padding-right: 1rem !important;
   }
   .pe-sm-4 {
     padding-right: 1.5rem !important;
   }
   .pe-sm-5 {
     padding-right: 3rem !important;
   }
   .pb-sm-0 {
     padding-bottom: 0 !important;
   }
   .pb-sm-1 {
     padding-bottom: 0.25rem !important;
   }
   .pb-sm-2 {
     padding-bottom: 0.5rem !important;
   }
   .pb-sm-3 {
     padding-bottom: 1rem !important;
   }
   .pb-sm-4 {
     padding-bottom: 1.5rem !important;
   }
   .pb-sm-5 {
     padding-bottom: 3rem !important;
   }
   .ps-sm-0 {
     padding-left: 0 !important;
   }
   .ps-sm-1 {
     padding-left: 0.25rem !important;
   }
   .ps-sm-2 {
     padding-left: 0.5rem !important;
   }
   .ps-sm-3 {
     padding-left: 1rem !important;
   }
   .ps-sm-4 {
     padding-left: 1.5rem !important;
   }
   .ps-sm-5 {
     padding-left: 3rem !important;
   }
   .text-sm-start {
     text-align: left !important;
   }
   .text-sm-end {
     text-align: right !important;
   }
   .text-sm-center {
     text-align: center !important;
   }
 }
 @media (min-width: 768px) {
   .float-md-start {
     float: left !important;
   }
   .float-md-end {
     float: right !important;
   }
   .float-md-none {
     float: none !important;
   }
   .d-md-inline {
     display: inline !important;
   }
   .d-md-inline-block {
     display: inline-block !important;
   }
   .d-md-block {
     display: block !important;
   }
   .d-md-grid {
     display: grid !important;
   }
   .d-md-table {
     display: table !important;
   }
   .d-md-table-row {
     display: table-row !important;
   }
   .d-md-table-cell {
     display: table-cell !important;
   }
   .d-md-flex {
     display: flex !important;
   }
   .d-md-inline-flex {
     display: inline-flex !important;
   }
   .d-md-none {
     display: none !important;
   }
   .flex-md-fill {
     flex: 1 1 auto !important;
   }
   .flex-md-row {
     flex-direction: row !important;
   }
   .flex-md-column {
     flex-direction: column !important;
   }
   .flex-md-row-reverse {
     flex-direction: row-reverse !important;
   }
   .flex-md-column-reverse {
     flex-direction: column-reverse !important;
   }
   .flex-md-grow-0 {
     flex-grow: 0 !important;
   }
   .flex-md-grow-1 {
     flex-grow: 1 !important;
   }
   .flex-md-shrink-0 {
     flex-shrink: 0 !important;
   }
   .flex-md-shrink-1 {
     flex-shrink: 1 !important;
   }
   .flex-md-wrap {
     flex-wrap: wrap !important;
   }
   .flex-md-nowrap {
     flex-wrap: nowrap !important;
   }
   .flex-md-wrap-reverse {
     flex-wrap: wrap-reverse !important;
   }
   .gap-md-0 {
     gap: 0 !important;
   }
   .gap-md-1 {
     gap: 0.25rem !important;
   }
   .gap-md-2 {
     gap: 0.5rem !important;
   }
   .gap-md-3 {
     gap: 1rem !important;
   }
   .gap-md-4 {
     gap: 1.5rem !important;
   }
   .gap-md-5 {
     gap: 3rem !important;
   }
   .justify-content-md-start {
     justify-content: flex-start !important;
   }
   .justify-content-md-end {
     justify-content: flex-end !important;
   }
   .justify-content-md-center {
     justify-content: center !important;
   }
   .justify-content-md-between {
     justify-content: space-between !important;
   }
   .justify-content-md-around {
     justify-content: space-around !important;
   }
   .justify-content-md-evenly {
     justify-content: space-evenly !important;
   }
   .align-items-md-start {
     align-items: flex-start !important;
   }
   .align-items-md-end {
     align-items: flex-end !important;
   }
   .align-items-md-center {
     align-items: center !important;
   }
   .align-items-md-baseline {
     align-items: baseline !important;
   }
   .align-items-md-stretch {
     align-items: stretch !important;
   }
   .align-content-md-start {
     align-content: flex-start !important;
   }
   .align-content-md-end {
     align-content: flex-end !important;
   }
   .align-content-md-center {
     align-content: center !important;
   }
   .align-content-md-between {
     align-content: space-between !important;
   }
   .align-content-md-around {
     align-content: space-around !important;
   }
   .align-content-md-stretch {
     align-content: stretch !important;
   }
   .align-self-md-auto {
     align-self: auto !important;
   }
   .align-self-md-start {
     align-self: flex-start !important;
   }
   .align-self-md-end {
     align-self: flex-end !important;
   }
   .align-self-md-center {
     align-self: center !important;
   }
   .align-self-md-baseline {
     align-self: baseline !important;
   }
   .align-self-md-stretch {
     align-self: stretch !important;
   }
   .order-md-first {
     order: -1 !important;
   }
   .order-md-0 {
     order: 0 !important;
   }
   .order-md-1 {
     order: 1 !important;
   }
   .order-md-2 {
     order: 2 !important;
   }
   .order-md-3 {
     order: 3 !important;
   }
   .order-md-4 {
     order: 4 !important;
   }
   .order-md-5 {
     order: 5 !important;
   }
   .order-md-last {
     order: 6 !important;
   }
   .m-md-0 {
     margin: 0 !important;
   }
   .m-md-1 {
     margin: 0.25rem !important;
   }
   .m-md-2 {
     margin: 0.5rem !important;
   }
   .m-md-3 {
     margin: 1rem !important;
   }
   .m-md-4 {
     margin: 1.5rem !important;
   }
   .m-md-5 {
     margin: 3rem !important;
   }
   .m-md-auto {
     margin: auto !important;
   }
   .mx-md-0 {
     margin-right: 0 !important;
     margin-left: 0 !important;
   }
   .mx-md-1 {
     margin-right: 0.25rem !important;
     margin-left: 0.25rem !important;
   }
   .mx-md-2 {
     margin-right: 0.5rem !important;
     margin-left: 0.5rem !important;
   }
   .mx-md-3 {
     margin-right: 1rem !important;
     margin-left: 1rem !important;
   }
   .mx-md-4 {
     margin-right: 1.5rem !important;
     margin-left: 1.5rem !important;
   }
   .mx-md-5 {
     margin-right: 3rem !important;
     margin-left: 3rem !important;
   }
   .mx-md-auto {
     margin-right: auto !important;
     margin-left: auto !important;
   }
   .my-md-0 {
     margin-top: 0 !important;
     margin-bottom: 0 !important;
   }
   .my-md-1 {
     margin-top: 0.25rem !important;
     margin-bottom: 0.25rem !important;
   }
   .my-md-2 {
     margin-top: 0.5rem !important;
     margin-bottom: 0.5rem !important;
   }
   .my-md-3 {
     margin-top: 1rem !important;
     margin-bottom: 1rem !important;
   }
   .my-md-4 {
     margin-top: 1.5rem !important;
     margin-bottom: 1.5rem !important;
   }
   .my-md-5 {
     margin-top: 3rem !important;
     margin-bottom: 3rem !important;
   }
   .my-md-auto {
     margin-top: auto !important;
     margin-bottom: auto !important;
   }
   .mt-md-0 {
     margin-top: 0 !important;
   }
   .mt-md-1 {
     margin-top: 0.25rem !important;
   }
   .mt-md-2 {
     margin-top: 0.5rem !important;
   }
   .mt-md-3 {
     margin-top: 1rem !important;
   }
   .mt-md-4 {
     margin-top: 1.5rem !important;
   }
   .mt-md-5 {
     margin-top: 3rem !important;
   }
   .mt-md-auto {
     margin-top: auto !important;
   }
   .me-md-0 {
     margin-right: 0 !important;
   }
   .me-md-1 {
     margin-right: 0.25rem !important;
   }
   .me-md-2 {
     margin-right: 0.5rem !important;
   }
   .me-md-3 {
     margin-right: 1rem !important;
   }
   .me-md-4 {
     margin-right: 1.5rem !important;
   }
   .me-md-5 {
     margin-right: 3rem !important;
   }
   .me-md-auto {
     margin-right: auto !important;
   }
   .mb-md-0 {
     margin-bottom: 0 !important;
   }
   .mb-md-1 {
     margin-bottom: 0.25rem !important;
   }
   .mb-md-2 {
     margin-bottom: 0.5rem !important;
   }
   .mb-md-3 {
     margin-bottom: 1rem !important;
   }
   .mb-md-4 {
     margin-bottom: 1.5rem !important;
   }
   .mb-md-5 {
     margin-bottom: 3rem !important;
   }
   .mb-md-auto {
     margin-bottom: auto !important;
   }
   .ms-md-0 {
     margin-left: 0 !important;
   }
   .ms-md-1 {
     margin-left: 0.25rem !important;
   }
   .ms-md-2 {
     margin-left: 0.5rem !important;
   }
   .ms-md-3 {
     margin-left: 1rem !important;
   }
   .ms-md-4 {
     margin-left: 1.5rem !important;
   }
   .ms-md-5 {
     margin-left: 3rem !important;
   }
   .ms-md-auto {
     margin-left: auto !important;
   }
   .p-md-0 {
     padding: 0 !important;
   }
   .p-md-1 {
     padding: 0.25rem !important;
   }
   .p-md-2 {
     padding: 0.5rem !important;
   }
   .p-md-3 {
     padding: 1rem !important;
   }
   .p-md-4 {
     padding: 1.5rem !important;
   }
   .p-md-5 {
     padding: 3rem !important;
   }
   .px-md-0 {
     padding-right: 0 !important;
     padding-left: 0 !important;
   }
   .px-md-1 {
     padding-right: 0.25rem !important;
     padding-left: 0.25rem !important;
   }
   .px-md-2 {
     padding-right: 0.5rem !important;
     padding-left: 0.5rem !important;
   }
   .px-md-3 {
     padding-right: 1rem !important;
     padding-left: 1rem !important;
   }
   .px-md-4 {
     padding-right: 1.5rem !important;
     padding-left: 1.5rem !important;
   }
   .px-md-5 {
     padding-right: 3rem !important;
     padding-left: 3rem !important;
   }
   .py-md-0 {
     padding-top: 0 !important;
     padding-bottom: 0 !important;
   }
   .py-md-1 {
     padding-top: 0.25rem !important;
     padding-bottom: 0.25rem !important;
   }
   .py-md-2 {
     padding-top: 0.5rem !important;
     padding-bottom: 0.5rem !important;
   }
   .py-md-3 {
     padding-top: 1rem !important;
     padding-bottom: 1rem !important;
   }
   .py-md-4 {
     padding-top: 1.5rem !important;
     padding-bottom: 1.5rem !important;
   }
   .py-md-5 {
     padding-top: 3rem !important;
     padding-bottom: 3rem !important;
   }
   .pt-md-0 {
     padding-top: 0 !important;
   }
   .pt-md-1 {
     padding-top: 0.25rem !important;
   }
   .pt-md-2 {
     padding-top: 0.5rem !important;
   }
   .pt-md-3 {
     padding-top: 1rem !important;
   }
   .pt-md-4 {
     padding-top: 1.5rem !important;
   }
   .pt-md-5 {
     padding-top: 3rem !important;
   }
   .pe-md-0 {
     padding-right: 0 !important;
   }
   .pe-md-1 {
     padding-right: 0.25rem !important;
   }
   .pe-md-2 {
     padding-right: 0.5rem !important;
   }
   .pe-md-3 {
     padding-right: 1rem !important;
   }
   .pe-md-4 {
     padding-right: 1.5rem !important;
   }
   .pe-md-5 {
     padding-right: 3rem !important;
   }
   .pb-md-0 {
     padding-bottom: 0 !important;
   }
   .pb-md-1 {
     padding-bottom: 0.25rem !important;
   }
   .pb-md-2 {
     padding-bottom: 0.5rem !important;
   }
   .pb-md-3 {
     padding-bottom: 1rem !important;
   }
   .pb-md-4 {
     padding-bottom: 1.5rem !important;
   }
   .pb-md-5 {
     padding-bottom: 3rem !important;
   }
   .ps-md-0 {
     padding-left: 0 !important;
   }
   .ps-md-1 {
     padding-left: 0.25rem !important;
   }
   .ps-md-2 {
     padding-left: 0.5rem !important;
   }
   .ps-md-3 {
     padding-left: 1rem !important;
   }
   .ps-md-4 {
     padding-left: 1.5rem !important;
   }
   .ps-md-5 {
     padding-left: 3rem !important;
   }
   .text-md-start {
     text-align: left !important;
   }
   .text-md-end {
     text-align: right !important;
   }
   .text-md-center {
     text-align: center !important;
   }
 }
 @media (min-width: 992px) {
   .float-lg-start {
     float: left !important;
   }
   .float-lg-end {
     float: right !important;
   }
   .float-lg-none {
     float: none !important;
   }
   .d-lg-inline {
     display: inline !important;
   }
   .d-lg-inline-block {
     display: inline-block !important;
   }
   .d-lg-block {
     display: block !important;
   }
   .d-lg-grid {
     display: grid !important;
   }
   .d-lg-table {
     display: table !important;
   }
   .d-lg-table-row {
     display: table-row !important;
   }
   .d-lg-table-cell {
     display: table-cell !important;
   }
   .d-lg-flex {
     display: flex !important;
   }
   .d-lg-inline-flex {
     display: inline-flex !important;
   }
   .d-lg-none {
     display: none !important;
   }
   .flex-lg-fill {
     flex: 1 1 auto !important;
   }
   .flex-lg-row {
     flex-direction: row !important;
   }
   .flex-lg-column {
     flex-direction: column !important;
   }
   .flex-lg-row-reverse {
     flex-direction: row-reverse !important;
   }
   .flex-lg-column-reverse {
     flex-direction: column-reverse !important;
   }
   .flex-lg-grow-0 {
     flex-grow: 0 !important;
   }
   .flex-lg-grow-1 {
     flex-grow: 1 !important;
   }
   .flex-lg-shrink-0 {
     flex-shrink: 0 !important;
   }
   .flex-lg-shrink-1 {
     flex-shrink: 1 !important;
   }
   .flex-lg-wrap {
     flex-wrap: wrap !important;
   }
   .flex-lg-nowrap {
     flex-wrap: nowrap !important;
   }
   .flex-lg-wrap-reverse {
     flex-wrap: wrap-reverse !important;
   }
   .gap-lg-0 {
     gap: 0 !important;
   }
   .gap-lg-1 {
     gap: 0.25rem !important;
   }
   .gap-lg-2 {
     gap: 0.5rem !important;
   }
   .gap-lg-3 {
     gap: 1rem !important;
   }
   .gap-lg-4 {
     gap: 1.5rem !important;
   }
   .gap-lg-5 {
     gap: 3rem !important;
   }
   .justify-content-lg-start {
     justify-content: flex-start !important;
   }
   .justify-content-lg-end {
     justify-content: flex-end !important;
   }
   .justify-content-lg-center {
     justify-content: center !important;
   }
   .justify-content-lg-between {
     justify-content: space-between !important;
   }
   .justify-content-lg-around {
     justify-content: space-around !important;
   }
   .justify-content-lg-evenly {
     justify-content: space-evenly !important;
   }
   .align-items-lg-start {
     align-items: flex-start !important;
   }
   .align-items-lg-end {
     align-items: flex-end !important;
   }
   .align-items-lg-center {
     align-items: center !important;
   }
   .align-items-lg-baseline {
     align-items: baseline !important;
   }
   .align-items-lg-stretch {
     align-items: stretch !important;
   }
   .align-content-lg-start {
     align-content: flex-start !important;
   }
   .align-content-lg-end {
     align-content: flex-end !important;
   }
   .align-content-lg-center {
     align-content: center !important;
   }
   .align-content-lg-between {
     align-content: space-between !important;
   }
   .align-content-lg-around {
     align-content: space-around !important;
   }
   .align-content-lg-stretch {
     align-content: stretch !important;
   }
   .align-self-lg-auto {
     align-self: auto !important;
   }
   .align-self-lg-start {
     align-self: flex-start !important;
   }
   .align-self-lg-end {
     align-self: flex-end !important;
   }
   .align-self-lg-center {
     align-self: center !important;
   }
   .align-self-lg-baseline {
     align-self: baseline !important;
   }
   .align-self-lg-stretch {
     align-self: stretch !important;
   }
   .order-lg-first {
     order: -1 !important;
   }
   .order-lg-0 {
     order: 0 !important;
   }
   .order-lg-1 {
     order: 1 !important;
   }
   .order-lg-2 {
     order: 2 !important;
   }
   .order-lg-3 {
     order: 3 !important;
   }
   .order-lg-4 {
     order: 4 !important;
   }
   .order-lg-5 {
     order: 5 !important;
   }
   .order-lg-last {
     order: 6 !important;
   }
   .m-lg-0 {
     margin: 0 !important;
   }
   .m-lg-1 {
     margin: 0.25rem !important;
   }
   .m-lg-2 {
     margin: 0.5rem !important;
   }
   .m-lg-3 {
     margin: 1rem !important;
   }
   .m-lg-4 {
     margin: 1.5rem !important;
   }
   .m-lg-5 {
     margin: 3rem !important;
   }
   .m-lg-auto {
     margin: auto !important;
   }
   .mx-lg-0 {
     margin-right: 0 !important;
     margin-left: 0 !important;
   }
   .mx-lg-1 {
     margin-right: 0.25rem !important;
     margin-left: 0.25rem !important;
   }
   .mx-lg-2 {
     margin-right: 0.5rem !important;
     margin-left: 0.5rem !important;
   }
   .mx-lg-3 {
     margin-right: 1rem !important;
     margin-left: 1rem !important;
   }
   .mx-lg-4 {
     margin-right: 1.5rem !important;
     margin-left: 1.5rem !important;
   }
   .mx-lg-5 {
     margin-right: 3rem !important;
     margin-left: 3rem !important;
   }
   .mx-lg-auto {
     margin-right: auto !important;
     margin-left: auto !important;
   }
   .my-lg-0 {
     margin-top: 0 !important;
     margin-bottom: 0 !important;
   }
   .my-lg-1 {
     margin-top: 0.25rem !important;
     margin-bottom: 0.25rem !important;
   }
   .my-lg-2 {
     margin-top: 0.5rem !important;
     margin-bottom: 0.5rem !important;
   }
   .my-lg-3 {
     margin-top: 1rem !important;
     margin-bottom: 1rem !important;
   }
   .my-lg-4 {
     margin-top: 1.5rem !important;
     margin-bottom: 1.5rem !important;
   }
   .my-lg-5 {
     margin-top: 3rem !important;
     margin-bottom: 3rem !important;
   }
   .my-lg-auto {
     margin-top: auto !important;
     margin-bottom: auto !important;
   }
   .mt-lg-0 {
     margin-top: 0 !important;
   }
   .mt-lg-1 {
     margin-top: 0.25rem !important;
   }
   .mt-lg-2 {
     margin-top: 0.5rem !important;
   }
   .mt-lg-3 {
     margin-top: 1rem !important;
   }
   .mt-lg-4 {
     margin-top: 1.5rem !important;
   }
   .mt-lg-5 {
     margin-top: 3rem !important;
   }
   .mt-lg-auto {
     margin-top: auto !important;
   }
   .me-lg-0 {
     margin-right: 0 !important;
   }
   .me-lg-1 {
     margin-right: 0.25rem !important;
   }
   .me-lg-2 {
     margin-right: 0.5rem !important;
   }
   .me-lg-3 {
     margin-right: 1rem !important;
   }
   .me-lg-4 {
     margin-right: 1.5rem !important;
   }
   .me-lg-5 {
     margin-right: 3rem !important;
   }
   .me-lg-auto {
     margin-right: auto !important;
   }
   .mb-lg-0 {
     margin-bottom: 0 !important;
   }
   .mb-lg-1 {
     margin-bottom: 0.25rem !important;
   }
   .mb-lg-2 {
     margin-bottom: 0.5rem !important;
   }
   .mb-lg-3 {
     margin-bottom: 1rem !important;
   }
   .mb-lg-4 {
     margin-bottom: 1.5rem !important;
   }
   .mb-lg-5 {
     margin-bottom: 3rem !important;
   }
   .mb-lg-auto {
     margin-bottom: auto !important;
   }
   .ms-lg-0 {
     margin-left: 0 !important;
   }
   .ms-lg-1 {
     margin-left: 0.25rem !important;
   }
   .ms-lg-2 {
     margin-left: 0.5rem !important;
   }
   .ms-lg-3 {
     margin-left: 1rem !important;
   }
   .ms-lg-4 {
     margin-left: 1.5rem !important;
   }
   .ms-lg-5 {
     margin-left: 3rem !important;
   }
   .ms-lg-auto {
     margin-left: auto !important;
   }
   .p-lg-0 {
     padding: 0 !important;
   }
   .p-lg-1 {
     padding: 0.25rem !important;
   }
   .p-lg-2 {
     padding: 0.5rem !important;
   }
   .p-lg-3 {
     padding: 1rem !important;
   }
   .p-lg-4 {
     padding: 1.5rem !important;
   }
   .p-lg-5 {
     padding: 3rem !important;
   }
   .px-lg-0 {
     padding-right: 0 !important;
     padding-left: 0 !important;
   }
   .px-lg-1 {
     padding-right: 0.25rem !important;
     padding-left: 0.25rem !important;
   }
   .px-lg-2 {
     padding-right: 0.5rem !important;
     padding-left: 0.5rem !important;
   }
   .px-lg-3 {
     padding-right: 1rem !important;
     padding-left: 1rem !important;
   }
   .px-lg-4 {
     padding-right: 1.5rem !important;
     padding-left: 1.5rem !important;
   }
   .px-lg-5 {
     padding-right: 3rem !important;
     padding-left: 3rem !important;
   }
   .py-lg-0 {
     padding-top: 0 !important;
     padding-bottom: 0 !important;
   }
   .py-lg-1 {
     padding-top: 0.25rem !important;
     padding-bottom: 0.25rem !important;
   }
   .py-lg-2 {
     padding-top: 0.5rem !important;
     padding-bottom: 0.5rem !important;
   }
   .py-lg-3 {
     padding-top: 1rem !important;
     padding-bottom: 1rem !important;
   }
   .py-lg-4 {
     padding-top: 1.5rem !important;
     padding-bottom: 1.5rem !important;
   }
   .py-lg-5 {
     padding-top: 3rem !important;
     padding-bottom: 3rem !important;
   }
   .pt-lg-0 {
     padding-top: 0 !important;
   }
   .pt-lg-1 {
     padding-top: 0.25rem !important;
   }
   .pt-lg-2 {
     padding-top: 0.5rem !important;
   }
   .pt-lg-3 {
     padding-top: 1rem !important;
   }
   .pt-lg-4 {
     padding-top: 1.5rem !important;
   }
   .pt-lg-5 {
     padding-top: 3rem !important;
   }
   .pe-lg-0 {
     padding-right: 0 !important;
   }
   .pe-lg-1 {
     padding-right: 0.25rem !important;
   }
   .pe-lg-2 {
     padding-right: 0.5rem !important;
   }
   .pe-lg-3 {
     padding-right: 1rem !important;
   }
   .pe-lg-4 {
     padding-right: 1.5rem !important;
   }
   .pe-lg-5 {
     padding-right: 3rem !important;
   }
   .pb-lg-0 {
     padding-bottom: 0 !important;
   }
   .pb-lg-1 {
     padding-bottom: 0.25rem !important;
   }
   .pb-lg-2 {
     padding-bottom: 0.5rem !important;
   }
   .pb-lg-3 {
     padding-bottom: 1rem !important;
   }
   .pb-lg-4 {
     padding-bottom: 1.5rem !important;
   }
   .pb-lg-5 {
     padding-bottom: 3rem !important;
   }
   .ps-lg-0 {
     padding-left: 0 !important;
   }
   .ps-lg-1 {
     padding-left: 0.25rem !important;
   }
   .ps-lg-2 {
     padding-left: 0.5rem !important;
   }
   .ps-lg-3 {
     padding-left: 1rem !important;
   }
   .ps-lg-4 {
     padding-left: 1.5rem !important;
   }
   .ps-lg-5 {
     padding-left: 3rem !important;
   }
   .text-lg-start {
     text-align: left !important;
   }
   .text-lg-end {
     text-align: right !important;
   }
   .text-lg-center {
     text-align: center !important;
   }
 }
 @media (min-width: 1200px) {
   .float-xl-start {
     float: left !important;
   }
   .float-xl-end {
     float: right !important;
   }
   .float-xl-none {
     float: none !important;
   }
   .d-xl-inline {
     display: inline !important;
   }
   .d-xl-inline-block {
     display: inline-block !important;
   }
   .d-xl-block {
     display: block !important;
   }
   .d-xl-grid {
     display: grid !important;
   }
   .d-xl-table {
     display: table !important;
   }
   .d-xl-table-row {
     display: table-row !important;
   }
   .d-xl-table-cell {
     display: table-cell !important;
   }
   .d-xl-flex {
     display: flex !important;
   }
   .d-xl-inline-flex {
     display: inline-flex !important;
   }
   .d-xl-none {
     display: none !important;
   }
   .flex-xl-fill {
     flex: 1 1 auto !important;
   }
   .flex-xl-row {
     flex-direction: row !important;
   }
   .flex-xl-column {
     flex-direction: column !important;
   }
   .flex-xl-row-reverse {
     flex-direction: row-reverse !important;
   }
   .flex-xl-column-reverse {
     flex-direction: column-reverse !important;
   }
   .flex-xl-grow-0 {
     flex-grow: 0 !important;
   }
   .flex-xl-grow-1 {
     flex-grow: 1 !important;
   }
   .flex-xl-shrink-0 {
     flex-shrink: 0 !important;
   }
   .flex-xl-shrink-1 {
     flex-shrink: 1 !important;
   }
   .flex-xl-wrap {
     flex-wrap: wrap !important;
   }
   .flex-xl-nowrap {
     flex-wrap: nowrap !important;
   }
   .flex-xl-wrap-reverse {
     flex-wrap: wrap-reverse !important;
   }
   .gap-xl-0 {
     gap: 0 !important;
   }
   .gap-xl-1 {
     gap: 0.25rem !important;
   }
   .gap-xl-2 {
     gap: 0.5rem !important;
   }
   .gap-xl-3 {
     gap: 1rem !important;
   }
   .gap-xl-4 {
     gap: 1.5rem !important;
   }
   .gap-xl-5 {
     gap: 3rem !important;
   }
   .justify-content-xl-start {
     justify-content: flex-start !important;
   }
   .justify-content-xl-end {
     justify-content: flex-end !important;
   }
   .justify-content-xl-center {
     justify-content: center !important;
   }
   .justify-content-xl-between {
     justify-content: space-between !important;
   }
   .justify-content-xl-around {
     justify-content: space-around !important;
   }
   .justify-content-xl-evenly {
     justify-content: space-evenly !important;
   }
   .align-items-xl-start {
     align-items: flex-start !important;
   }
   .align-items-xl-end {
     align-items: flex-end !important;
   }
   .align-items-xl-center {
     align-items: center !important;
   }
   .align-items-xl-baseline {
     align-items: baseline !important;
   }
   .align-items-xl-stretch {
     align-items: stretch !important;
   }
   .align-content-xl-start {
     align-content: flex-start !important;
   }
   .align-content-xl-end {
     align-content: flex-end !important;
   }
   .align-content-xl-center {
     align-content: center !important;
   }
   .align-content-xl-between {
     align-content: space-between !important;
   }
   .align-content-xl-around {
     align-content: space-around !important;
   }
   .align-content-xl-stretch {
     align-content: stretch !important;
   }
   .align-self-xl-auto {
     align-self: auto !important;
   }
   .align-self-xl-start {
     align-self: flex-start !important;
   }
   .align-self-xl-end {
     align-self: flex-end !important;
   }
   .align-self-xl-center {
     align-self: center !important;
   }
   .align-self-xl-baseline {
     align-self: baseline !important;
   }
   .align-self-xl-stretch {
     align-self: stretch !important;
   }
   .order-xl-first {
     order: -1 !important;
   }
   .order-xl-0 {
     order: 0 !important;
   }
   .order-xl-1 {
     order: 1 !important;
   }
   .order-xl-2 {
     order: 2 !important;
   }
   .order-xl-3 {
     order: 3 !important;
   }
   .order-xl-4 {
     order: 4 !important;
   }
   .order-xl-5 {
     order: 5 !important;
   }
   .order-xl-last {
     order: 6 !important;
   }
   .m-xl-0 {
     margin: 0 !important;
   }
   .m-xl-1 {
     margin: 0.25rem !important;
   }
   .m-xl-2 {
     margin: 0.5rem !important;
   }
   .m-xl-3 {
     margin: 1rem !important;
   }
   .m-xl-4 {
     margin: 1.5rem !important;
   }
   .m-xl-5 {
     margin: 3rem !important;
   }
   .m-xl-auto {
     margin: auto !important;
   }
   .mx-xl-0 {
     margin-right: 0 !important;
     margin-left: 0 !important;
   }
   .mx-xl-1 {
     margin-right: 0.25rem !important;
     margin-left: 0.25rem !important;
   }
   .mx-xl-2 {
     margin-right: 0.5rem !important;
     margin-left: 0.5rem !important;
   }
   .mx-xl-3 {
     margin-right: 1rem !important;
     margin-left: 1rem !important;
   }
   .mx-xl-4 {
     margin-right: 1.5rem !important;
     margin-left: 1.5rem !important;
   }
   .mx-xl-5 {
     margin-right: 3rem !important;
     margin-left: 3rem !important;
   }
   .mx-xl-auto {
     margin-right: auto !important;
     margin-left: auto !important;
   }
   .my-xl-0 {
     margin-top: 0 !important;
     margin-bottom: 0 !important;
   }
   .my-xl-1 {
     margin-top: 0.25rem !important;
     margin-bottom: 0.25rem !important;
   }
   .my-xl-2 {
     margin-top: 0.5rem !important;
     margin-bottom: 0.5rem !important;
   }
   .my-xl-3 {
     margin-top: 1rem !important;
     margin-bottom: 1rem !important;
   }
   .my-xl-4 {
     margin-top: 1.5rem !important;
     margin-bottom: 1.5rem !important;
   }
   .my-xl-5 {
     margin-top: 3rem !important;
     margin-bottom: 3rem !important;
   }
   .my-xl-auto {
     margin-top: auto !important;
     margin-bottom: auto !important;
   }
   .mt-xl-0 {
     margin-top: 0 !important;
   }
   .mt-xl-1 {
     margin-top: 0.25rem !important;
   }
   .mt-xl-2 {
     margin-top: 0.5rem !important;
   }
   .mt-xl-3 {
     margin-top: 1rem !important;
   }
   .mt-xl-4 {
     margin-top: 1.5rem !important;
   }
   .mt-xl-5 {
     margin-top: 3rem !important;
   }
   .mt-xl-auto {
     margin-top: auto !important;
   }
   .me-xl-0 {
     margin-right: 0 !important;
   }
   .me-xl-1 {
     margin-right: 0.25rem !important;
   }
   .me-xl-2 {
     margin-right: 0.5rem !important;
   }
   .me-xl-3 {
     margin-right: 1rem !important;
   }
   .me-xl-4 {
     margin-right: 1.5rem !important;
   }
   .me-xl-5 {
     margin-right: 3rem !important;
   }
   .me-xl-auto {
     margin-right: auto !important;
   }
   .mb-xl-0 {
     margin-bottom: 0 !important;
   }
   .mb-xl-1 {
     margin-bottom: 0.25rem !important;
   }
   .mb-xl-2 {
     margin-bottom: 0.5rem !important;
   }
   .mb-xl-3 {
     margin-bottom: 1rem !important;
   }
   .mb-xl-4 {
     margin-bottom: 1.5rem !important;
   }
   .mb-xl-5 {
     margin-bottom: 3rem !important;
   }
   .mb-xl-auto {
     margin-bottom: auto !important;
   }
   .ms-xl-0 {
     margin-left: 0 !important;
   }
   .ms-xl-1 {
     margin-left: 0.25rem !important;
   }
   .ms-xl-2 {
     margin-left: 0.5rem !important;
   }
   .ms-xl-3 {
     margin-left: 1rem !important;
   }
   .ms-xl-4 {
     margin-left: 1.5rem !important;
   }
   .ms-xl-5 {
     margin-left: 3rem !important;
   }
   .ms-xl-auto {
     margin-left: auto !important;
   }
   .p-xl-0 {
     padding: 0 !important;
   }
   .p-xl-1 {
     padding: 0.25rem !important;
   }
   .p-xl-2 {
     padding: 0.5rem !important;
   }
   .p-xl-3 {
     padding: 1rem !important;
   }
   .p-xl-4 {
     padding: 1.5rem !important;
   }
   .p-xl-5 {
     padding: 3rem !important;
   }
   .px-xl-0 {
     padding-right: 0 !important;
     padding-left: 0 !important;
   }
   .px-xl-1 {
     padding-right: 0.25rem !important;
     padding-left: 0.25rem !important;
   }
   .px-xl-2 {
     padding-right: 0.5rem !important;
     padding-left: 0.5rem !important;
   }
   .px-xl-3 {
     padding-right: 1rem !important;
     padding-left: 1rem !important;
   }
   .px-xl-4 {
     padding-right: 1.5rem !important;
     padding-left: 1.5rem !important;
   }
   .px-xl-5 {
     padding-right: 3rem !important;
     padding-left: 3rem !important;
   }
   .py-xl-0 {
     padding-top: 0 !important;
     padding-bottom: 0 !important;
   }
   .py-xl-1 {
     padding-top: 0.25rem !important;
     padding-bottom: 0.25rem !important;
   }
   .py-xl-2 {
     padding-top: 0.5rem !important;
     padding-bottom: 0.5rem !important;
   }
   .py-xl-3 {
     padding-top: 1rem !important;
     padding-bottom: 1rem !important;
   }
   .py-xl-4 {
     padding-top: 1.5rem !important;
     padding-bottom: 1.5rem !important;
   }
   .py-xl-5 {
     padding-top: 3rem !important;
     padding-bottom: 3rem !important;
   }
   .pt-xl-0 {
     padding-top: 0 !important;
   }
   .pt-xl-1 {
     padding-top: 0.25rem !important;
   }
   .pt-xl-2 {
     padding-top: 0.5rem !important;
   }
   .pt-xl-3 {
     padding-top: 1rem !important;
   }
   .pt-xl-4 {
     padding-top: 1.5rem !important;
   }
   .pt-xl-5 {
     padding-top: 3rem !important;
   }
   .pe-xl-0 {
     padding-right: 0 !important;
   }
   .pe-xl-1 {
     padding-right: 0.25rem !important;
   }
   .pe-xl-2 {
     padding-right: 0.5rem !important;
   }
   .pe-xl-3 {
     padding-right: 1rem !important;
   }
   .pe-xl-4 {
     padding-right: 1.5rem !important;
   }
   .pe-xl-5 {
     padding-right: 3rem !important;
   }
   .pb-xl-0 {
     padding-bottom: 0 !important;
   }
   .pb-xl-1 {
     padding-bottom: 0.25rem !important;
   }
   .pb-xl-2 {
     padding-bottom: 0.5rem !important;
   }
   .pb-xl-3 {
     padding-bottom: 1rem !important;
   }
   .pb-xl-4 {
     padding-bottom: 1.5rem !important;
   }
   .pb-xl-5 {
     padding-bottom: 3rem !important;
   }
   .ps-xl-0 {
     padding-left: 0 !important;
   }
   .ps-xl-1 {
     padding-left: 0.25rem !important;
   }
   .ps-xl-2 {
     padding-left: 0.5rem !important;
   }
   .ps-xl-3 {
     padding-left: 1rem !important;
   }
   .ps-xl-4 {
     padding-left: 1.5rem !important;
   }
   .ps-xl-5 {
     padding-left: 3rem !important;
   }
   .text-xl-start {
     text-align: left !important;
   }
   .text-xl-end {
     text-align: right !important;
   }
   .text-xl-center {
     text-align: center !important;
   }
 }
 @media (min-width: 1400px) {
   .float-xxl-start {
     float: left !important;
   }
   .float-xxl-end {
     float: right !important;
   }
   .float-xxl-none {
     float: none !important;
   }
   .d-xxl-inline {
     display: inline !important;
   }
   .d-xxl-inline-block {
     display: inline-block !important;
   }
   .d-xxl-block {
     display: block !important;
   }
   .d-xxl-grid {
     display: grid !important;
   }
   .d-xxl-table {
     display: table !important;
   }
   .d-xxl-table-row {
     display: table-row !important;
   }
   .d-xxl-table-cell {
     display: table-cell !important;
   }
   .d-xxl-flex {
     display: flex !important;
   }
   .d-xxl-inline-flex {
     display: inline-flex !important;
   }
   .d-xxl-none {
     display: none !important;
   }
   .flex-xxl-fill {
     flex: 1 1 auto !important;
   }
   .flex-xxl-row {
     flex-direction: row !important;
   }
   .flex-xxl-column {
     flex-direction: column !important;
   }
   .flex-xxl-row-reverse {
     flex-direction: row-reverse !important;
   }
   .flex-xxl-column-reverse {
     flex-direction: column-reverse !important;
   }
   .flex-xxl-grow-0 {
     flex-grow: 0 !important;
   }
   .flex-xxl-grow-1 {
     flex-grow: 1 !important;
   }
   .flex-xxl-shrink-0 {
     flex-shrink: 0 !important;
   }
   .flex-xxl-shrink-1 {
     flex-shrink: 1 !important;
   }
   .flex-xxl-wrap {
     flex-wrap: wrap !important;
   }
   .flex-xxl-nowrap {
     flex-wrap: nowrap !important;
   }
   .flex-xxl-wrap-reverse {
     flex-wrap: wrap-reverse !important;
   }
   .gap-xxl-0 {
     gap: 0 !important;
   }
   .gap-xxl-1 {
     gap: 0.25rem !important;
   }
   .gap-xxl-2 {
     gap: 0.5rem !important;
   }
   .gap-xxl-3 {
     gap: 1rem !important;
   }
   .gap-xxl-4 {
     gap: 1.5rem !important;
   }
   .gap-xxl-5 {
     gap: 3rem !important;
   }
   .justify-content-xxl-start {
     justify-content: flex-start !important;
   }
   .justify-content-xxl-end {
     justify-content: flex-end !important;
   }
   .justify-content-xxl-center {
     justify-content: center !important;
   }
   .justify-content-xxl-between {
     justify-content: space-between !important;
   }
   .justify-content-xxl-around {
     justify-content: space-around !important;
   }
   .justify-content-xxl-evenly {
     justify-content: space-evenly !important;
   }
   .align-items-xxl-start {
     align-items: flex-start !important;
   }
   .align-items-xxl-end {
     align-items: flex-end !important;
   }
   .align-items-xxl-center {
     align-items: center !important;
   }
   .align-items-xxl-baseline {
     align-items: baseline !important;
   }
   .align-items-xxl-stretch {
     align-items: stretch !important;
   }
   .align-content-xxl-start {
     align-content: flex-start !important;
   }
   .align-content-xxl-end {
     align-content: flex-end !important;
   }
   .align-content-xxl-center {
     align-content: center !important;
   }
   .align-content-xxl-between {
     align-content: space-between !important;
   }
   .align-content-xxl-around {
     align-content: space-around !important;
   }
   .align-content-xxl-stretch {
     align-content: stretch !important;
   }
   .align-self-xxl-auto {
     align-self: auto !important;
   }
   .align-self-xxl-start {
     align-self: flex-start !important;
   }
   .align-self-xxl-end {
     align-self: flex-end !important;
   }
   .align-self-xxl-center {
     align-self: center !important;
   }
   .align-self-xxl-baseline {
     align-self: baseline !important;
   }
   .align-self-xxl-stretch {
     align-self: stretch !important;
   }
   .order-xxl-first {
     order: -1 !important;
   }
   .order-xxl-0 {
     order: 0 !important;
   }
   .order-xxl-1 {
     order: 1 !important;
   }
   .order-xxl-2 {
     order: 2 !important;
   }
   .order-xxl-3 {
     order: 3 !important;
   }
   .order-xxl-4 {
     order: 4 !important;
   }
   .order-xxl-5 {
     order: 5 !important;
   }
   .order-xxl-last {
     order: 6 !important;
   }
   .m-xxl-0 {
     margin: 0 !important;
   }
   .m-xxl-1 {
     margin: 0.25rem !important;
   }
   .m-xxl-2 {
     margin: 0.5rem !important;
   }
   .m-xxl-3 {
     margin: 1rem !important;
   }
   .m-xxl-4 {
     margin: 1.5rem !important;
   }
   .m-xxl-5 {
     margin: 3rem !important;
   }
   .m-xxl-auto {
     margin: auto !important;
   }
   .mx-xxl-0 {
     margin-right: 0 !important;
     margin-left: 0 !important;
   }
   .mx-xxl-1 {
     margin-right: 0.25rem !important;
     margin-left: 0.25rem !important;
   }
   .mx-xxl-2 {
     margin-right: 0.5rem !important;
     margin-left: 0.5rem !important;
   }
   .mx-xxl-3 {
     margin-right: 1rem !important;
     margin-left: 1rem !important;
   }
   .mx-xxl-4 {
     margin-right: 1.5rem !important;
     margin-left: 1.5rem !important;
   }
   .mx-xxl-5 {
     margin-right: 3rem !important;
     margin-left: 3rem !important;
   }
   .mx-xxl-auto {
     margin-right: auto !important;
     margin-left: auto !important;
   }
   .my-xxl-0 {
     margin-top: 0 !important;
     margin-bottom: 0 !important;
   }
   .my-xxl-1 {
     margin-top: 0.25rem !important;
     margin-bottom: 0.25rem !important;
   }
   .my-xxl-2 {
     margin-top: 0.5rem !important;
     margin-bottom: 0.5rem !important;
   }
   .my-xxl-3 {
     margin-top: 1rem !important;
     margin-bottom: 1rem !important;
   }
   .my-xxl-4 {
     margin-top: 1.5rem !important;
     margin-bottom: 1.5rem !important;
   }
   .my-xxl-5 {
     margin-top: 3rem !important;
     margin-bottom: 3rem !important;
   }
   .my-xxl-auto {
     margin-top: auto !important;
     margin-bottom: auto !important;
   }
   .mt-xxl-0 {
     margin-top: 0 !important;
   }
   .mt-xxl-1 {
     margin-top: 0.25rem !important;
   }
   .mt-xxl-2 {
     margin-top: 0.5rem !important;
   }
   .mt-xxl-3 {
     margin-top: 1rem !important;
   }
   .mt-xxl-4 {
     margin-top: 1.5rem !important;
   }
   .mt-xxl-5 {
     margin-top: 3rem !important;
   }
   .mt-xxl-auto {
     margin-top: auto !important;
   }
   .me-xxl-0 {
     margin-right: 0 !important;
   }
   .me-xxl-1 {
     margin-right: 0.25rem !important;
   }
   .me-xxl-2 {
     margin-right: 0.5rem !important;
   }
   .me-xxl-3 {
     margin-right: 1rem !important;
   }
   .me-xxl-4 {
     margin-right: 1.5rem !important;
   }
   .me-xxl-5 {
     margin-right: 3rem !important;
   }
   .me-xxl-auto {
     margin-right: auto !important;
   }
   .mb-xxl-0 {
     margin-bottom: 0 !important;
   }
   .mb-xxl-1 {
     margin-bottom: 0.25rem !important;
   }
   .mb-xxl-2 {
     margin-bottom: 0.5rem !important;
   }
   .mb-xxl-3 {
     margin-bottom: 1rem !important;
   }
   .mb-xxl-4 {
     margin-bottom: 1.5rem !important;
   }
   .mb-xxl-5 {
     margin-bottom: 3rem !important;
   }
   .mb-xxl-auto {
     margin-bottom: auto !important;
   }
   .ms-xxl-0 {
     margin-left: 0 !important;
   }
   .ms-xxl-1 {
     margin-left: 0.25rem !important;
   }
   .ms-xxl-2 {
     margin-left: 0.5rem !important;
   }
   .ms-xxl-3 {
     margin-left: 1rem !important;
   }
   .ms-xxl-4 {
     margin-left: 1.5rem !important;
   }
   .ms-xxl-5 {
     margin-left: 3rem !important;
   }
   .ms-xxl-auto {
     margin-left: auto !important;
   }
   .p-xxl-0 {
     padding: 0 !important;
   }
   .p-xxl-1 {
     padding: 0.25rem !important;
   }
   .p-xxl-2 {
     padding: 0.5rem !important;
   }
   .p-xxl-3 {
     padding: 1rem !important;
   }
   .p-xxl-4 {
     padding: 1.5rem !important;
   }
   .p-xxl-5 {
     padding: 3rem !important;
   }
   .px-xxl-0 {
     padding-right: 0 !important;
     padding-left: 0 !important;
   }
   .px-xxl-1 {
     padding-right: 0.25rem !important;
     padding-left: 0.25rem !important;
   }
   .px-xxl-2 {
     padding-right: 0.5rem !important;
     padding-left: 0.5rem !important;
   }
   .px-xxl-3 {
     padding-right: 1rem !important;
     padding-left: 1rem !important;
   }
   .px-xxl-4 {
     padding-right: 1.5rem !important;
     padding-left: 1.5rem !important;
   }
   .px-xxl-5 {
     padding-right: 3rem !important;
     padding-left: 3rem !important;
   }
   .py-xxl-0 {
     padding-top: 0 !important;
     padding-bottom: 0 !important;
   }
   .py-xxl-1 {
     padding-top: 0.25rem !important;
     padding-bottom: 0.25rem !important;
   }
   .py-xxl-2 {
     padding-top: 0.5rem !important;
     padding-bottom: 0.5rem !important;
   }
   .py-xxl-3 {
     padding-top: 1rem !important;
     padding-bottom: 1rem !important;
   }
   .py-xxl-4 {
     padding-top: 1.5rem !important;
     padding-bottom: 1.5rem !important;
   }
   .py-xxl-5 {
     padding-top: 3rem !important;
     padding-bottom: 3rem !important;
   }
   .pt-xxl-0 {
     padding-top: 0 !important;
   }
   .pt-xxl-1 {
     padding-top: 0.25rem !important;
   }
   .pt-xxl-2 {
     padding-top: 0.5rem !important;
   }
   .pt-xxl-3 {
     padding-top: 1rem !important;
   }
   .pt-xxl-4 {
     padding-top: 1.5rem !important;
   }
   .pt-xxl-5 {
     padding-top: 3rem !important;
   }
   .pe-xxl-0 {
     padding-right: 0 !important;
   }
   .pe-xxl-1 {
     padding-right: 0.25rem !important;
   }
   .pe-xxl-2 {
     padding-right: 0.5rem !important;
   }
   .pe-xxl-3 {
     padding-right: 1rem !important;
   }
   .pe-xxl-4 {
     padding-right: 1.5rem !important;
   }
   .pe-xxl-5 {
     padding-right: 3rem !important;
   }
   .pb-xxl-0 {
     padding-bottom: 0 !important;
   }
   .pb-xxl-1 {
     padding-bottom: 0.25rem !important;
   }
   .pb-xxl-2 {
     padding-bottom: 0.5rem !important;
   }
   .pb-xxl-3 {
     padding-bottom: 1rem !important;
   }
   .pb-xxl-4 {
     padding-bottom: 1.5rem !important;
   }
   .pb-xxl-5 {
     padding-bottom: 3rem !important;
   }
   .ps-xxl-0 {
     padding-left: 0 !important;
   }
   .ps-xxl-1 {
     padding-left: 0.25rem !important;
   }
   .ps-xxl-2 {
     padding-left: 0.5rem !important;
   }
   .ps-xxl-3 {
     padding-left: 1rem !important;
   }
   .ps-xxl-4 {
     padding-left: 1.5rem !important;
   }
   .ps-xxl-5 {
     padding-left: 3rem !important;
   }
   .text-xxl-start {
     text-align: left !important;
   }
   .text-xxl-end {
     text-align: right !important;
   }
   .text-xxl-center {
     text-align: center !important;
   }
 }
 @media (min-width: 1200px) {
   .fs-1 {
     font-size: 2.5rem !important;
   }
   .fs-2 {
     font-size: 2rem !important;
   }
   .fs-3 {
     font-size: 1.75rem !important;
   }
   .fs-4 {
     font-size: 1.5rem !important;
   }
 }
 @media print {
   .d-print-inline {
     display: inline !important;
   }
   .d-print-inline-block {
     display: inline-block !important;
   }
   .d-print-block {
     display: block !important;
   }
   .d-print-grid {
     display: grid !important;
   }
   .d-print-table {
     display: table !important;
   }
   .d-print-table-row {
     display: table-row !important;
   }
   .d-print-table-cell {
     display: table-cell !important;
   }
   .d-print-flex {
     display: flex !important;
   }
   .d-print-inline-flex {
     display: inline-flex !important;
   }
   .d-print-none {
     display: none !important;
   }
 }
 /*  typography */
 body {
   line-height: 1.7;
   //font-family: "Rubik", sans-serif;
   -webkit-font-smoothing: antialiased;
   font-size: 15px;
   font-weight: 400;
   color: $color-black;
 }
 
 p, .paragraph {
   font-size: 16px;
 }
 
 .lead {
   color: #555555;
 }
 
 h1, h2, h3, h4, h5, h6,
 .h1, .h2, .h3, .h4, .h5, .h6 {
   color: $color-primary;
   //font-family: "Rubik", sans-serif;
   //@debug: 700;
 }
 
 h1, .h1 {
   font-size: 44px;
 }
 @media (max-width: 767px) {
   h1, .h1 {
     font-size: 38px;
   }
 }
 
 h2, .h2 {
   font-size: 36px;
 }
 @media (max-width: 767px) {
   h2, .h2 {
     font-size: 30px;
   }
 }
 
 h3, .h3 {
   font-size: 24px;
 }
 
 h4, .h4 {
   font-size: 22px;
 }
 
 h5, .h5 {
   font-size: 18px;
 }
 
 h6, .h6 {
   font-size: 14px;
 }
 
 /* Button style */
 .btn {
   font-size: 15px;
   text-transform: capitalize;
   padding: 13px 28px;
   border-radius: 10px;
   position: relative;
   z-index: 1;
   font-weight: 500;
   transition: 0.2s ease;
   overflow: hidden;
   border: 0;
 }
 .btn::after {
   position: absolute;
   content: "";
   height: 15px;
   width: 15px;
   background-color: #3c8d53;
   top: 100%;
   left: 60%;
   z-index: -1;
   border-radius: 50%;
   transition: 0.3s;
   transform: rotate(25deg);
 }
 .btn.w-100::after, .btn.d-block::after, .btn.d-flex::after, .btn.effect-none::after {
   display: none;
 }
 .btn-sm {
   padding: 13px 25px;
 }
 .btn:hover, .btn:active, .btn.active, .btn:focus, .btn.focus {
   outline: 0;
   box-shadow: none !important;
   text-decoration: none;
 }
 .btn:hover::after, .btn:active::after, .btn.active::after, .btn:focus::after, .btn.focus::after {
   top: -75px;
   left: -55px;
   height: 300px;
   width: 300px;
   transition: 0.4s;
   transform: rotate(0deg);
 }
 
 .btn-primary {
   background: $color-primary;
   color: #fff;
   border-color: $color-primary;
 }
 .btn-primary:hover, .btn-primary:active, .btn-primary.active, .btn-primary:focus, .btn-primary.focus {
   background: $color-primary !important;
   color: #fff;
 }
 .btn-primary.w-100:hover, .btn-primary.d-block:hover, .btn-primary.d-flex:hover, .btn-primary.effect-none:hover {
   background-color: #3f9457 !important;
 }
 
 .btn-outline-primary {
   background: #e0f2e5;
   color: #3f9457;
 }
 .btn-outline-primary::after {
   background-color: $color-primary;
 }
 .btn-outline-primary:hover, .btn-outline-primary:active, .btn-outline-primary.active, .btn-outline-primary:focus, .btn-outline-primary.focus {
   background: #e0f2e5;
   color: #fff;
 }
 .btn-outline-primary.w-100:hover, .btn-outline-primary.d-block:hover, .btn-outline-primary.d-flex:hover, .btn-outline-primary.effect-none:hover {
   background: #e0f2e5;
 }
 
 .nav-pills .nav-link:hover {
   color: #3f9457 !important;
 }
 
 .nav-pills .nav-link.active, .nav-pills .show > .nav-link {
   background-color: $color-primary;
 }
 
 body {
   background-color: #fff;
   overflow-x: hidden;
 }
 
 ::-moz-selection {
   background: $color-primary;
   color: #fff;
 }
 
 ::selection {
   background: $color-primary;
   color: #fff;
 }
 
 /* preloader */
 .preloader {
   position: fixed;
   top: 0;
   left: 0;
   right: 0;
   bottom: 0;
   background-color: #fff;
   z-index: 9999;
   display: flex;
   align-items: center;
   justify-content: center;
 }
 
 img {
   vertical-align: middle;
   border: 0;
   max-width: 100%;
   height: auto;
 }
 
 a,
 a:hover,
 a:focus {
   text-decoration: none !important;
 }
 
 a,
 button,
 select {
   cursor: pointer;
 }
 a:focus,
 button:focus,
 select:focus {
   outline: 0;
 }
 
 a {
   color: $color-primary;
 }
 a:hover {
   color: $color-primary;
   text-decoration: underline;
 }
 
 .slick-slide {
   outline: 0;
 }
 
 .section {
   padding-top: 30px;
 }
 .section:last-child{
  padding-bottom: 30px;
 }
 .section-sm {
   padding-top: 20px;
   padding-bottom: 20px;
 }
 .section-title {
   margin-bottom: 30px;
 }
 
 .bg-cover {
   background-size: cover;
   background-position: center center;
   background-repeat: no-repeat;
 }
 
 .outline-0 {
   outline: 0 !important;
 }
 
 .bg-primary {
   background: $color-primary !important;
 }
 
 .bg-primary-light {
   background: white !important;
 }
 
 .bg-secondary {
   background: #917AEB !important;
 }
 
 .bg-secondary-light {
   background: white !important;
 }
 
 .bg-tertiary {
   background: #FBFBFB !important;
 }
 
 .bg-dark {
   background: #233242 !important;
 }
 
 .text-primary {
   color: $color-primary !important;
 }
 
 .text-black {
   color: #040404 !important;
 }
 
 .text-black a:hover,
 a.text-black:hover {
   color: $color-primary !important;
 }
 
 .text-default {
   color: #888888 !important;
 }
 
 .font-secondary {
   font-family: sans-serif !important;
 }
 
 .shadow {
   box-shadow: 0px 25px 65px 0px rgba(0, 0, 0, 0.05) !important;
 }
 
 .shadow-md, .difference-of-us-item:hover, .service-item:hover {
   box-shadow: 0px 25px 65px 0px rgba(0, 0, 0, 0.1) !important;
 }
 
 .rounded {
   border-radius: 8px !important;
 }
 
 .rounded-lg {
   border-radius: 15px !important;
 }
 
 .content * {
   word-break: break-word;
   overflow-wrap: break-word;
 }
 .content img {
   max-width: 100%;
   margin-top: 10px;
   margin-bottom: 35px;
 }
 .content h1, .content .h1,
 .content h2,
 .content .h2,
 .content h3,
 .content .h3,
 .content h4,
 .content .h4,
 .content h5,
 .content .h5,
 .content h6,
 .content .h6 {
   font-weight: 700;
   color: $color-primary;
 }
 .content strong {
   font-weight: 800;
 }
 .content a {
   border-bottom: 1px dotted $color-primary;
 }
 .content a:hover {
   color: $color-primary;
   border-bottom: 1px solid $color-primary;
 }
 .content ol,
 .content ul {
   margin-bottom: 25px;
   padding-left: 0;
   list-style-position: inside;
 }
 .content ol li,
 .content ul li {
   margin-bottom: 10px;
 }
 .content ul {
   margin-bottom: 20px;
   list-style-type: none;
 }
 .content ul li {
   padding-left: 0;
   position: relative;
 }
 .content ul li::before {
   margin-right: 10px;
   font-size: 13px;
 }
 .content p {
   margin-bottom: 16px;
 }
 .content h1, .content .h1,
 .content h2,
 .content .h2,
 .content h3,
 .content .h3,
 .content h4,
 .content .h4,
 .content h5,
 .content .h5,
 .content h6,
 .content .h6 {
   color: $color-primary;
   margin-bottom: 20px;
   margin-top: 30px;
   font-weight: 700;
 }
 .content blockquote {
   border: 1px solid #ddd;
   border-radius: 8px;
   padding: 15px 20px;
 }
 .content blockquote p {
   margin-bottom: 0;
 }
 .content blockquote p cite {
   display: inline-block;
   margin-top: 10px;
 }
 .content table {
   width: 100%;
   max-width: 100%;
   margin-bottom: 1rem;
   border: 1px solid #e0e0e0;
   background-color: rgba(255, 255, 255, 0.4);
   -webkit-backdrop-filter: blur(25px);
           backdrop-filter: blur(25px);
 }
 .content table td,
 .content table th {
   padding: 0.6rem 1rem;
   vertical-align: top;
   margin-bottom: 0;
   border: 1px solid #e0e0e0;
 }
 .content tr:not(:last-child) {
   border-bottom: 1px solid #e0e0e0;
 }
 .content th {
   font-weight: 500;
 }
 .content thead {
   margin-bottom: 0;
   background-color: rgba(255, 255, 255, 0.2);
 }
 .content thead tr {
   border-bottom: 1px solid #e0e0e0;
 }
 .content tbody {
   margin-bottom: 0;
 }
 .content .notices {
   margin: 2rem 0;
   position: relative;
   overflow: hidden;
   padding-top: 30px;
   z-index: 1;
 }
 .content .notices::before {
   position: absolute;
   content: "";
   height: 30px;
   width: 100%;
   top: 0;
   left: 0;
   z-index: -1;
   border-top-left-radius: 8px;
   border-top-right-radius: 8px;
 }
 .content .notices.note::before {
   background-color: #6ab0de;
 }
 .content .notices.tip::before {
   background-color: #78C578;
 }
 .content .notices.info::before {
   background-color: #F0B37E;
 }
 .content .notices.warning::before {
   background-color: #E06F6C;
 }
 .content .notices p {
   padding: 10px;
   margin-bottom: 0;
   border: 1px solid #ddd;
   border-top: 0;
   border-bottom-left-radius: 8px;
   border-bottom-right-radius: 8px;
 }
 .content .notices p::after {
   position: absolute;
   top: 2px;
   color: #fff;
   left: 2rem;
 }
 .content .notices.note p::after {
   content: "Note";
 }
 .content .notices.tip p::after {
   content: "Tip";
 }
 .content .notices.info p::after {
   content: "Info";
 }
 .content .notices.warning p::after {
   content: "Warning";
 }
 .content pre {
   display: block;
   padding: 10px 15px;
   margin: 10px 0px 10px;
   white-space: pre-wrap;
 }
 .content code {
   margin-bottom: 0 !important;
   font-size: 100%;
 }
 .content hr {
   margin: 30px 0;
 }
 
 .content.check-list ul li::before {
   content: "\f00c";
   font-weight: 900;
   font-family: "Font Awesome 5 Free";
   background-color: transparent;
   height: unset;
   width: unset;
   color: $color-primary;
   top: 0;
   left: 0;
 }
 
 .accordion-button {
   padding: 0.8rem 1.25rem;
 }
 .accordion-button:focus {
   border-color: #dfdfdf;
   box-shadow: none;
 }
 
 .code-tabs {
   overflow: hidden;
   margin-bottom: 20px;
 }
 .code-tabs .nav-tabs {
   margin-bottom: 0;
   border-bottom: 0;
 }
 .code-tabs .nav-item {
   padding-left: 0;
 }
 .code-tabs .nav-item.active .nav-link {
   color: #fff;
 }
 .code-tabs .nav-item.active .nav-link {
   background: $color-primary;
 }
 .code-tabs .nav-item .nav-link {
   text-decoration: none;
   font-weight: 500;
   border: 0;
   border-radius: 8px;
   text-transform: capitalize;
   color: $color-primary;
 }
 .code-tabs .tab-content {
   padding: 20px 15px;
   border: 1px solid #ddd;
   border-radius: 8px;
 }
 
 .card-img {
   position: relative;
   overflow: hidden;
 }
 
 .card-social {
   margin-bottom: 0;
   background-color: #fff;
   box-shadow: 0px 25px 65px rgba(0, 0, 0, 0.05);
   border-radius: 6px;
   display: inline-block;
   padding: 12px 15px;
   position: absolute;
   left: 50%;
   transform: translateX(-50%);
   bottom: -100px;
   transition: 0.2s ease;
   white-space: nowrap;
 }
 .card-social a {
   height: 35px;
   width: 35px;
   line-height: 35px;
   background-color: #fff;
   color: #233242;
   border: 1px solid #ddd;
   display: block;
   border-radius: 6px;
   font-size: 15px;
 }
 .card-social a:hover {
   color: $color-primary;
 }
 
 .card:hover .card-social {
   bottom: 20px;
 }
 
 .notices p i {
   position: absolute;
   top: 7px;
   left: 10px;
   color: #fff;
 }
 
 blockquote::before,
 .check-list ul li::before,
 .content ul:not(.nav) li::before,
 .notices p::before {
   font-family: "Font Awesome 5 Free";
   font-weight: 900;
 }
 
 .check-list ul li::before {
   content: "\f054";
 }
 
 .content ul:not(.nav) li {
   padding-left: 20px;
   position: relative;
 }
 
 .content ul:not(.nav) li::before {
   content: "\f192";
   position: absolute;
   left: 0;
   top: 2px;
   color: $color-primary;
 }
 
 blockquote::before {
   content: "\f10d";
   font-size: 36px;
 }
 
 .notices p::before {
   content: "\f05a";
   position: absolute;
   top: 2px;
   left: 9px;
   color: #fff;
 }
 
 .navbar-brand {
   font-family: "Rubik", sans-serif;
   font-size: 30px;
 }
 
 @media (min-width: 1199px) {
   .navigation .dropdown-menu {
     display: block;
     opacity: 0;
     visibility: hidden;
     min-width: 200px;
     transition: 0.2s;
     left: 50%;
     border-radius: 8px;
     transform: translate(-50%, 10px) scaleX(1.2);
     transform-origin: top;
   }
   .navigation .dropdown-menu li {
     opacity: 0;
     transition: 0.3s;
     transition-delay: 0.1s;
   }
   .navigation .dropdown:hover .dropdown-menu {
     opacity: 1;
     visibility: visible;
     box-shadow: 0 10px 30px rgba(22, 28, 45, 0.1);
     transform: translate(-50%, -6px) scale(1);
   }
   .navigation .dropdown:hover .dropdown-menu li {
     opacity: 1;
   }
 }
 .navigation .dropdown-menu {
   border: 0;
   padding: 12px 20px;
 }
 @media (max-width: 991px) {
   .navigation .dropdown-menu {
     text-align: center;
     border: 1px solid rgba(81, 181, 109, 0.2);
   }
 }
 .navigation .dropdown-item {
   padding: 6px 0;
   font-weight: 500;
 }
 .navigation .dropdown-item.active,
 .navigation .dropdown-item:active {
   color: $color-primary;
   background-color: transparent;
 }
 .navigation .dropdown-item:focus,
 .navigation .dropdown-item:hover {
   color: $color-primary;
   background-color: transparent;
 }
 .navigation .nav-item {
   margin: 0;
 }
 @media (min-width: 1199px) {
   .navigation .nav-item.dropdown .nav-link {
     margin-right: 16px;
   }
 }
 .navigation .nav-item.dropdown .nav-link::after {
   right: 0;
   top: calc(50% - 6px);
   display: inline-block;
   vertical-align: 0.255em;
   content: "";
   height: 8px;
   width: 8px;
   border: 2px solid;
   border-left: 0;
   border-top: 0;
   border-color: inherit;
   border-radius: 2px;
   transform: rotate(45deg);
   transition: 0s;
 }
 @media (min-width: 1199px) {
   .navigation .nav-item.dropdown .nav-link::after {
     position: absolute;
   }
 }
 .navigation .nav-item .nav-link {
   font-weight: 500;
   text-transform: capitalize;
   position: relative;
   z-index: 1;
 }
 .navigation .nav-item .nav-link select {
   font-weight: 500;
   font-size: 15px;
 }
 @media (max-width: 1200px) {
   .navigation .nav-item .nav-link {
     padding: 10px 0 !important;
   }
 }
 .navigation .nav-item .nav-link::before {
   position: absolute;
   content: "";
   bottom: 10px;
   left: 15px;
   width: calc(100% - 30px);
   height: 2px;
   z-index: -1;
   background-color: $color-primary;
   transform: scaleX(0);
   transition: 0.25s;
 }
 @media (max-width: 991px) {
   .navigation .nav-item .nav-link::before {
     display: none;
   }
 }
 .navigation .nav-item:hover .nav-link {
   text-decoration: none;
   color: $color-primary !important;
 }
 .navigation .nav-item:hover .nav-link::before {
   transform: scaleX(1);
 }
 .navigation .navbar-light .navbar-nav .nav-link {
   color: #040404;
 }
 .navigation .navbar-light .navbar-nav .nav-link:hover {
   color: $color-primary;
 }
 .navigation .navbar-light .navbar-nav .active .nav-link {
   color: $color-primary;
 }
 .navigation .navbar-light .navbar-nav .active .nav-link::before {
   transform: scaleX(1);
 }
 .navigation .navbar-expand-xl .navbar-nav .nav-link {
   padding: 15px;
 }
 
 .banner {
   padding: 90px 0;
   z-index: 5;
 }
 .banner .block h1, .banner .block .h1 {
   font-size: 52px;
 }
 @media (max-width: 991px) {
   .banner .block h1, .banner .block .h1 {
     font-size: 46px;
   }
 }
 @media (max-width: 767px) {
   .banner .block h1, .banner .block .h1 {
     font-size: 38px;
   }
 }
 .banner .has-shapes {
   z-index: -1;
 }
 .banner .has-shapes .shape {
   top: 0;
   height: 100%;
 }
 .banner .has-shapes .shape-left {
   left: 0;
   top: auto;
   bottom: -30px;
 }
 .banner .has-shapes .shape-right {
   right: 0;
 }
 
 .service-item {
   border-top: 1px solid #F4F4F4;
   border-right: 1px solid #F4F4F4;
   transition: 0.3s;
   position: relative;
   z-index: 10;
 }
 .service-item .colored-box {
   display: inline-block;
   height: 70px;
   width: 70px;
   line-height: 70px;
   border-radius: 8px;
   color: $color-primary;
   background-color: rgba(81, 181, 109, 0.2);
 }
 .service-item .service-title {
   transition: color 0.3s;
 }
 .service-item .service-description {
   color: #888888;
 }
 .service-item a:hover .service-title {
   color: $color-primary;
 }
 .service-item a:hover .service-description {
   color: #888888;
 }
 .service-item .block {
   padding: 35px 39px;
 }
 @media (min-width: 991px) {
   .service-item:nth-child(2), .service-item:nth-child(4) {
     border-left: 1px solid #F4F4F4;
   }
   .service-item:nth-child(4), .service-item:nth-child(5), .service-item:nth-child(6) {
     border-bottom: 1px solid #F4F4F4;
   }
 }
 @media (max-width: 991px) {
   .service-item:nth-child(2), .service-item:nth-child(3), .service-item:nth-child(5) {
     border-left: 1px solid #F4F4F4;
   }
   .service-item:nth-child(6), .service-item:nth-child(5) {
     border-bottom: 1px solid #F4F4F4;
   }
 }
 @media (max-width: 767px) {
   .service-item {
     border: 1px solid #F4F4F4;
     border-bottom: 0;
   }
 }
 .service-item:nth-child(even) .colored-box {
   color: #917AEB;
   background-color: rgba(145, 122, 235, 0.2);
 }
 .service-item:hover {
   border-color: transparent;
 }
 
 .about-section {
   z-index: 5;
 }
 .about-section .has-shapes {
   z-index: -1;
 }
 .about-section .has-shapes .shape-left {
   left: 0;
   bottom: 0;
 }
 .about-section .has-shapes .shape-right {
   right: 0;
   top: 0;
 }
 
 .difference-of-us-item {
   position: relative;
   transition: 0.3s;
 }
 .difference-of-us-item:not(:last-child) {
   margin-bottom: 10px;
 }
 .difference-of-us-item .icon {
   height: 85px;
   flex: 0 0 auto;
   width: 85px;
   line-height: 85px;
   text-align: center;
   border-radius: 8px;
   color: #917AEB;
   background-color: rgba(145, 122, 235, 0.1);
 }
 .difference-of-us-item:nth-child(even) .icon {
   color: $color-primary;
   background-color: rgba(81, 181, 109, 0.1);
 }
 
 .has-shapes {
   position: absolute;
   width: 100%;
   height: 100%;
   top: 0;
   left: 0;
   pointer-events: none;
 }
 .has-shapes .shape {
   z-index: 9;
   position: absolute;
 }
 
 .homepage_tab {
   z-index: 5;
 }
 @media (max-width: 767px) {
   .homepage_tab {
     overflow: hidden;
   }
 }
 .homepage_tab .payment_info_tab .active {
   color: #fff !important;
 }
 .homepage_tab .content-block,
 .homepage_tab .image-block {
   opacity: 0;
   transition: 0.3s;
   position: relative;
 }
 .homepage_tab .content-block {
   left: -25px;
 }
 .homepage_tab .image-block {
   right: -25px;
 }
 .homepage_tab .show .content-block,
 .homepage_tab .show .image-block {
   opacity: 1;
 }
 .homepage_tab .show .content-block {
   left: 0;
 }
 .homepage_tab .show .image-block {
   right: 0;
 }
 .homepage_tab > .container {
   position: relative;
   z-index: 10;
 }
 .homepage_tab > .container::after {
   position: absolute;
   content: "";
   height: 65%;
   width: 100%;
   top: 0;
   left: 0;
   background-color: #FBFBFB;
   z-index: -2;
   border-radius: 18px;
 }
 .homepage_tab .has-shapes {
   z-index: -1;
 }
 .homepage_tab .has-shapes .shape-left {
   top: 0;
   left: 0;
 }
 .homepage_tab .has-shapes .shape-right {
   top: 0;
   right: 0;
 }
 
 .page-header {
   position: relative;
   overflow: hidden;
   padding: 80px 0;
   z-index: 5;
 }
 .page-header .has-shapes {
   z-index: -1;
 }
 .page-header .has-shapes .shape {
   height: 170%;
 }
 .page-header .has-shapes .shape-left {
   left: 50px;
   top: -37%;
   transform: rotate(-35deg);
 }
 .page-header .has-shapes .shape-right {
   right: 0;
   bottom: 0;
 }
 .page-header .breadcrumbs,
 .page-header .breadcrumbs a {
   color: #111;
 }
 .page-header .breadcrumbs a:hover {
   color: $color-primary;
 }
 .page-header .breadcrumbs li:last-child a {
   pointer-events: none;
   opacity: 0.6;
 }
 
 .testimonials {
   position: relative;
   z-index: 5;
 }
 .testimonials .has-shapes {
   z-index: -1;
 }
 .testimonials .has-shapes .shape-left {
   left: 0;
   bottom: 0;
 }
 .testimonials .has-shapes .shape-right {
   top: 50%;
   transform: translateY(-50%);
   right: 0;
 }
 
 .accordion-item {
   border: 0;
 }
 .accordion-item .accordion-button {
   margin-bottom: 0;
 }
 .accordion-item:last-child {
   margin-bottom: 0 !important;
 }
 .accordion-item .accordion-button:not(.collapsed) {
   color: #040404;
   background-color: transparent;
   box-shadow: none;
 }
 .accordion-item .accordion-button::after {
   background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000000'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") !important;
   transform: rotateX(0);
 }
 .accordion-item .accordion-button.active::after {
   background-image: url("data:image/svg+xml,%3Csvg xmlns=%22http://www.w3.org/2000/svg%22 viewBox=%220 0 16 16%22 fill=%22%23000%22%3E%3Cpath fill-rule=%22evenodd%22 d=%22M1.646 4.646a.5.5.0 01.708.0L8 10.293l5.646-5.647a.5.5.0 01.708.708l-6 6a.5.5.0 01-.708.0l-6-6a.5.5.0 010-.708z%22/%3E%3C/svg%3E") !important;
   transform: rotateX(180deg);
 }
 
 .accordion-border-bottom .accordion-item:not(:last-child) {
   border-bottom: 1px solid #D6D6D6;
   padding-bottom: 20px;
   margin-bottom: 20px;
 }
 .accordion-border-bottom .accordion-item:not(:last-child) .content {
   padding-bottom: 30px;
 }
 
 .core-value .has-shapes {
   z-index: 2;
   pointer-events: none;
 }
 .core-value .shape-1 {
   right: -14px;
   top: -25px;
 }
 .core-value .shape-2 {
   left: -30px;
   bottom: -20px;
 }
 
 .has-video-popup .video-play-btn {
   position: absolute;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
   transition: 0.3s;
 }
 .has-video-popup .video-play-btn:hover {
   transform: translate(-50%, -50%) scale(1.05);
 }
 .has-video-popup .video-play-btn svg {
   height: 90px;
   width: 90px;
 }
 @media (max-width: 767px) {
   .has-video-popup .video-play-btn svg {
     height: 60px;
     width: 60px;
     margin-left: -35px;
   }
 }
 
 .loan-steps .count {
   height: 70px;
   width: 70px;
   text-align: center;
   line-height: 70px;
   margin: 15px auto 20px;
   font-size: 24px;
   font-weight: 700;
   border-radius: 50%;
   color: #fff;
   position: relative;
   font-family: "Rubik", sans-serif;
 }
 .loan-steps .count::after {
   position: absolute;
   content: "";
   height: 100%;
   width: 100%;
   border: 6px solid rgba(239, 255, 238, 0.5);
   top: 0;
   left: 0;
   border-radius: 50%;
 }
 .loan-steps .step-item {
   margin-top: 30px;
   position: relative;
   z-index: 1;
 }
 .loan-steps .step-item::before {
   position: absolute;
   content: "";
   height: 1px;
   width: 100%;
   border: 1px dashed $color-primary;
   top: 50px;
   left: 50%;
   z-index: -1;
 }
 .loan-steps .step-item:nth-child(3)::before, .loan-steps .step-item:nth-child(6)::before, .loan-steps .step-item:nth-child(9)::before, .loan-steps .step-item:last-child::before {
   display: none;
 }
 .loan-steps .step-item .count {
   background-color: $color-primary;
 }
 @media (max-width: 991px) {
   .loan-steps .step-item:nth-child(2)::before, .loan-steps .step-item:nth-child(3)::before, .loan-steps .step-item:nth-child(6)::before {
     display: none;
   }
   .loan-steps .step-item:nth-child(1)::before, .loan-steps .step-item:nth-child(6)::before, .loan-steps .step-item:nth-child(9)::before {
     display: block;
   }
 }
 @media (max-width: 767px) {
   .loan-steps .step-item::before {
     display: none !important;
   }
 }
 
 .icon-box-item {
   z-index: 3;
 }
 .icon-box-item .block {
   padding: 35px 27px;
   box-shadow: 0px 25px 65px 0px rgba(0, 0, 0, 0.05);
   border-radius: 15px;
 }
 .icon-box-item .icon {
   display: inline-block;
   height: 90px;
   width: 90px;
   line-height: 90px;
   font-size: 32px;
   border-radius: 10px;
   margin-bottom: 20px;
   text-align: center;
   color: #fff;
   background-color: $color-primary;
 }
 
 .icon-lg {
   height: unset !important;
   width: unset !important;
   line-height: 1 !important;
 }
 .icon-lg i {
   font-size: 40px;
 }
 
 .rounded-number {
   font-size: 24px !important;
   height: 70px !important;
   width: 70px !important;
   border-radius: 8px !important;
   font-weight: 800;
   line-height: 70px !important;
 }
 
 .form-control {
   color: #233242 !important;
 }
 
 .form-control::-moz-placeholder {
   opacity: 0.5;
 }
 
 .form-control::placeholder {
   opacity: 0.5;
 }
 
 .form-control:disabled, .form-control[readonly] {
   background-color: #fff;
   cursor: no-drop;
 }
 
 .form-control:not(textarea) {
   height: 55px;
 }
 
 textarea.form-control {
   height: 140px;
 }
 
 .form-control:focus {
   border-color: $color-primary;
 }
 
 .form-group {
   position: relative;
   margin-top: 8px;
 }
 .form-group label {
   position: absolute;
   background-color: #fff;
   top: -13px;
   left: 12px;
   padding: 0 9px;
 }
 
 .contact-info {
   padding-left: 50px;
 }
 @media (max-width: 991px) {
   .contact-info {
     padding-left: 0;
   }
 }
 .contact-info .block .icon {
   margin-right: 15px;
   font-size: 18px;
   line-height: 0;
   position: relative;
   top: 5px;
 }
 .contact-info .block .social-links li {
   margin-right: 15px;
 }
 .contact-info .block .social-links a {
   font-size: 22px;
 }
 
 .newsletter-form {
   margin-top: -35px;
 }
 @media (max-width: 991px) {
   .newsletter-form {
     margin-top: 0;
   }
 }
 .newsletter-form input,
 .newsletter-form .input-group-text {
   border-color: rgba(81, 181, 109, 0.4) !important;
 }
 .newsletter-form input {
   border-radius: 8px;
   padding: 0 15px;
 }
 .newsletter-form .input-group-text {
   border-top-right-radius: 6px;
   border-bottom-right-radius: 6px;
 }
 .newsletter-form button {
   color: #fff;
   border-radius: 0px;
   padding: 19px;
 }
 
 .footer-widget ul li a {
   color: #000;
 }
 .footer-widget ul li a:hover {
   color: $color-primary;
   text-decoration: underline !important;
 }
 
 .social-icons a {
   display: inline-block;
   height: 45px;
   width: 45px;
   border: 1px solid #ddd;
   display: flex;
   align-items: center;
   justify-content: center;
   border-radius: 8px;
 }
 
 #TableOfContents ul {
   margin-bottom: 0;
   margin-top: 15px;
 }
 #TableOfContents ul ul {
   margin-top: 0;
 }
 #TableOfContents a {
   display: block;
   padding: 8px 0;
   color: #040404;
   font-weight: 400;
   transition: 0.2s ease;
 }
 #TableOfContents a.active {
   color: $color-primary;
 }
 #TableOfContents ul {
   padding-left: 0;
 }
 #TableOfContents ul li {
   list-style-type: none;
   padding-left: 20px;
   position: relative;
   color: #040404;
 }
 #TableOfContents ul li::before {
   position: absolute;
   content: "";
   height: 8px;
   width: 8px;
   border-radius: 50%;
   background: $color-primary;
   left: 3px;
   top: 15px;
 }
 
 .has-line-end {
   display: inline-block;
   position: relative;
 }
 .has-line-end::before {
   position: absolute;
   left: calc(100% + 10px);
   top: 50%;
   transform: translateY(-50%);
   content: "";
   height: 1px;
   width: 30px;
   background-color: #233242;
 }
 
 .teams .position-relative {
   z-index: 1;
 }
 
 .post-slider {
   overflow: hidden;
 }
 .post-slider:hover .prevArrow {
   left: 30px;
 }
 .post-slider:hover .nextArrow {
   right: 30px;
 }
 
 .image-fallback {
   position: relative;
   font-size: 0;
 }
 .image-fallback span {
   position: absolute;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
 }
 .image-fallback span::first-letter {
   font-size: 72px;
   color: #000;
 }
 
 .slider-sm .slick-arrow {
   height: 35px;
   width: 35px;
   line-height: 35px;
 }
 
 .slick-arrow {
   position: absolute;
   top: 50%;
   transform: translateY(-50%);
   z-index: 9;
   height: 50px;
   width: 50px;
   line-height: 50px;
   border-radius: 50%;
   background: rgba(81, 181, 109, 0.5);
   color: #fff;
   border: 0;
 }
 @media (max-width: 575px) {
   .slick-arrow {
     display: none;
   }
 }
 .slick-arrow:focus {
   outline: 0;
 }
 .slick-arrow:hover {
   background: $color-primary;
 }
 
 .prevArrow {
   left: -60px;
 }
 
 .nextArrow {
   right: -60px;
 }
 
 .pagination .page-item .page-link {
   display: inline-block;
   width: 48px;
   height: 48px;
   line-height: 48px;
   border-radius: 8px;
   background: transparent;
   text-align: center;
   padding: 0;
   margin-right: 10px;
   border: 1px solid $color-primary;
   color: $color-primary;
 }
 .pagination .page-item .page-link:hover {
   background: $color-primary;
   color: #fff;
   border-color: $color-primary;
   text-decoration: none;
 }
 .pagination .page-item .page-link:focus {
   box-shadow: none;
 }
 .pagination .page-item:first-child .page-link, .pagination .page-item:last-child .page-link {
   border-radius: 8px;
 }
 .pagination .page-item.active .page-link {
   background: $color-primary;
   color: #fff;
   border-color: $color-primary;
 }
 
 .widget {
   margin-bottom: 30px;
 }
 .widget .widget-title {
   margin-bottom: 15px;
 }
 .widget.widget-categories li a, .widget.widget-tags li a {
   display: block;
   background: #fbfbfb;
   padding: 10px 18px;
   color: #000;
   margin-bottom: 5px;
   border-radius: 8px;
   text-decoration: none;
 }
 .widget.widget-categories li a:hover, .widget.widget-tags li a:hover {
   background-color: $color-primary;
   color: #fff;
 }
 .widget.widget-tags li a {
   display: inline-block;
   margin-bottom: 8px;
 }
 
 .widget-post .widget-post-image {
   width: 80px;
   height: 80px;
 }
 .widget-post .widget-post-image img {
   height: 100%;
   width: 100%;
   -o-object-fit: cover;
      object-fit: cover;
   -o-object-position: center;
      object-position: center;
 }
 
 .modal-dialog {
   max-width: 550px;
 }
 
 .applyLoanModal .modal-content {
   padding: 10px;
 }
 /*# sourceMappingURL=style.css.map */
 

 .inside.container ul li {
  list-style: disc;
  margin-left: 2em;
}

li.newslist__item {
  list-style: none !important;
  color: unset !important;
  padding-bottom: 2rem;
}


footer li.active span {
  color: $color-primary !important;
}


.section.first .row {
  h1, h2, h3, h4, h5 {
    margin-top: 2rem;
  }
}


.margin-left-m3 {
  margin-left: -2rem;
}