/* -------------------------------------------------------------
//  Cookielayer
// -----------------------------------------------------------*/
.cookie-layer {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  margin: 0;
  padding: 20px;
  left: 0;
  height: 100%;
  width: 100%;
  top: 0;
  z-index: 1000;
  overflow-y: scroll;
  overflow-x: hidden;
}
.cookie-layer.is-hidden {
  display: none;
}
.cookie-layer__body {
  background-color: $color-white;
  padding: 20px 20px 0 20px;
  height: calc(100% - 0px);
  /* overflow-y: scroll; */
  overflow-x: hidden;
}
.cookie-layer__wrapper {
  height: calc(100% - 170px);
  /* overflow-y: scroll; */
  overflow-x: hidden;
  overflow-y: scroll;
}
.trackingheadline {
  font-size: 1.5rem;
  font-weight: 700;
}
.cookiefieldset {
  border: 0 none;
  padding-left: 0;
}
.cookielist {
  display: block;
}
.cookiebuttons {
  padding-top: 20px;
  text-align: left;
}
.cookielist__item {
  list-style: none;
  margin-right: 20px;
  text-align: left;
  margin-bottom: 10px;
}
.checkbox--cookie {
  margin-right: 10px;
}
.accordion--cookie {
  background-color: $color-white !important;
  padding-left: 0;
}
.accordion--cookie {
  margin: 0;
}
.accordion-title--cookie {
  margin-left: 30px;
}
.accordion-icon--cookie {
  top: 12px;
  left: 0;
  right: auto;
}
.accordion-panel--cookie {
  padding-left: 0;
  padding-right: 0;
  text-align: left;
}
@media only screen and (min-width: 450px) {
  .cookie-layer {
  }
  .cookielist {
    display: flex;
  }
}
@media only screen and (min-width: 768px) {
  .cookie-layer,
  .cookie-layer__wrapper {
    overflow-y: hidden;
  }
  .cookie-layer__body {
    height: auto;
    overflow-y: hidden;
    max-width: 900px;
    margin: 0 auto;
  }
  .cookiebuttons {
    text-align: right;
  }
}
