$colors: (
  "color-black": $color-black,
  "color-black-90": $color-black-90,
  "color-black-80": $color-black-80,
  "color-black-70": $color-black-70,
  "color-black-60": $color-black-60,
  "color-black-50": $color-black-50,
  "color-black-40": $color-black-40,
  "color-black-30": $color-black-30,
  "color-black-20": $color-black-20,
  "color-black-10": $color-black-10,
  "color-black-05": $color-black-05,
  "color-black-01": $color-black-01,
  "color-white": $color-white,
  "color-primary-dark": $color-primary-dark,
  "color-primary": $color-primary,
  "color-primary-light": $color-primary-light,
  "color-secondary-dark": $color-secondary-dark,
  "color-secondary": $color-secondary,
  "color-secondary-light": $color-secondary-light,
  "color-accent-dark": $color-accent-dark,
  "color-accent": $color-accent,
  "color-accent-light": $color-accent-light,
  "color-danger-dark": $color-danger-dark,
  "color-danger": $color-danger,
  "color-danger-light": $color-danger-light,
  "color-info-dark": $color-info-dark,
  "color-info": $color-info,
  "color-info-light": $color-info-light,
  "color-sucess-dark": $color-success-dark,
  "color-sucess": $color-success,
  "color-sucess-light": $color-success-light,
  "color-warning-dark": $color-warning-dark,
  "color-warning": $color-warning,
  "color-warning-light": $color-warning-light,
);

/* -------------------------------------------------------------
//  Colors
// -----------------------------------------------------------*/

@each $name, $color in $colors {
  .#{$name} {
    color: #{$color} !important;
  }
}

/* -------------------------------------------------------------
//  Background-Colors
// -----------------------------------------------------------*/

@each $name, $color in $colors {
  .bg-#{$name} {
    background-color: #{$color} !important;
    // color: text-color-checker($color-black-90, $color);
  }
}
