/* -------------------------------------------------------------
//  Carousel
// -----------------------------------------------------------*/

.glide {
  position: relative;
  width: 100%;
  box-sizing: border-box;

  & * {
    box-sizing: inherit;
  }

  &__track {
    overflow: hidden;
  }

  &__slides {
    position: relative;
    width: 100%;
    list-style: none;
    backface-visibility: hidden;
    transform-style: preserve-3d;
    touch-action: pan-Y;
    overflow: hidden;
    margin: 0;
    padding: 0;
    white-space: nowrap;
    display: flex;
    flex-wrap: nowrap;
    will-change: transform;
  }

  &__slides--dragging {
    user-select: none;
  }

  &__slide {
    width: 100%;
    height: 100%;
    flex-shrink: 0;
    white-space: normal;
    user-select: none;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: transparent;
    background-color: $slider-background-color;
    min-height: 250px; 
  }

  &__slide a {
    user-select: none;
    -webkit-user-drag: none;
    -moz-user-select: none;
    -ms-user-select: none;
  }

  &__arrows {
    -webkit-touch-callout: none;
    user-select: none;
  }

  &__arrow {
    color: $color-primary !important;
    background-color: $color-white !important;
    margin-top: -10px;
    position: absolute;
    top: 50%;
    display: block;
    z-index: 2;
    text-transform: uppercase;
    padding: 0.75rem 0.75rem;
    background-color: transparent;
    border-radius: 50%;
    box-shadow: 0 0.25em 0.5em 0 rgba(0, 0, 0, 0.1);
    opacity: 1;
    cursor: pointer;
    transition: opacity 150ms ease, border 300ms ease-in-out;
    transform: translateY(-50%);
    line-height: 1;

    &:focus { outline: none; }
    &:hover { border-color: white; }

    &--left {
      left: 2em;
    }

    &--right {
      right: 2em;
    }

    &--disabled {
      opacity: 0.33;
    }
  }

  &__bullets {
    position: absolute;
    z-index: 2;
    bottom: 2em;
    left: 50%;
    display: inline-flex;
    list-style: none;
    transform: translateX(-50%);
  }

  &__bullet {
    background-color: rgba(255, 255, 255, 0.5);
    width: 9px;
    height: 9px;
    padding: 0;
    border-radius: 50%;
    border: 2px solid transparent;
    transition: all 300ms ease-in-out;
    cursor: pointer;
    line-height: 0;
    box-shadow: 0 0.25em 0.5em 0 rgba(0, 0, 0, 0.1);
    margin: 0 0.25em;

    &:focus {
      outline: none;
    }

    &:hover,
    &:focus {
      border: 2px solid white;
      background-color: rgba(255, 255, 255, 0.5);
    }

    &--active {
      background-color: white;
    }
  }

  &--swipeable {
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;
  }

  &--dragging {
    cursor: grabbing;
    cursor: -moz-grabbing;
    cursor: -webkit-grabbing;
  }

  &--rtl {
    direction: rtl;
  }
}
