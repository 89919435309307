/* -------------------------------------------------------------
//  Links
// -----------------------------------------------------------*/
.link {
  text-decoration: none !important;

  &:hover {
    border-bottom: 1px solid $link-color-dark;
  }
}
.nav-link {
  text-decoration: none !important;
  &:hover {
    color: $color-primary;
    text-decoration: underline !important;
  }
}
.navlist__link {
  text-decoration: none !important;
}
.mod_article {
  a {
    color: $color-primary;
    text-decoration: underline !important;
  }
}
a {
  color: $color-primary;
  text-decoration: underline !important;
}
a:hover {
  color: $color-primary-light;
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

