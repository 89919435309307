/* -------------------------------------------------------------
//  Images
// -----------------------------------------------------------*/
.ce_image {
  padding: 0 !important;
}

img {
  height: auto;
  margin-bottom: to-rem($font-medium-line-height);
}
.image_container {
  margin: 0;
}

.img-fluid {
  height: auto;
  width: auto;
}
.inside.container-fluid .image_container img {
  margin: 0;
  /* max-width: 1560px; */
  width: 100%;
  object-fit: initial;
  height: 500;
}
.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 0.875em;
  color: #6c757d;
}

a.logo img {
  width: auto;
}
.ce_gallery ul {
  padding: 0;
  display: grid; 
  grid-auto-flow: row dense; 
  grid-template-columns: 2fr 1fr 1fr; 
  grid-template-rows: 1fr 1fr; 
  gap: 0px 0px; 
  grid-template-areas: 
    "row_first . ."
    "row_first . ."; 
  .row_first { grid-area: row_first; }
  li {
    list-style: none !important;
    padding: 0;
    padding-left: 10px;
    padding-bottom: 9px;
   
    margin: 0 !important;

    img {
      object-fit: cover;
      height: 100%;
    }
  }
}