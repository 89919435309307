/* -------------------------------------------------------------
//  Radio Buttons
    https://moderncss.dev/pure-css-custom-checkbox-style/
// -----------------------------------------------------------*/

input[type="radio"] {
    appearance: none;
    // For iOS < 15 to remove gradient background
    background-color: #fff;
    // Not removed via appearance
    margin: 0 to-rem(calc($font-medium-font-size / 2)) 0 0;

    // Styling
    font: inherit;
    color: currentColor;
    width: to-rem($radio-size);
    height: to-rem($radio-size);
    border: 1px solid $radio-border-color;
    border-radius: 50%;
    transform: translateY(-0.075em); // prefered to flexbox due to multiline text could happen

    display: inline-grid;
    place-content: center;

    &::before {
        content: "";
        width: 0.65em;
        height: 0.65em;
        border-radius: 50%;
        transform: scale(0);
        transition: 120ms transform ease-in-out;
        box-shadow: inset 1em 1em $color-primary;
    }

    &:checked:before {
        transform: scale(1);
    }
  }