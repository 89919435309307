/* -------------------------------------------------------------
//  Navigation
// -----------------------------------------------------------*/

.navwrapper {
  background: #ffffff;
  display: flex;
  flex-direction: column;
  left: 0;
  padding: 25px;
  position: absolute;
  top: -460px;
  width: 100%;
  z-index: -1;
  -webkit-transition: top 500ms;
  -moz-transition: top 500ms;
  -ms-transition: top 500ms;
  -o-transition: top 500ms;
  transition: top 500ms;
}

.navwrapper.active {
  top: 86px;
}

.height--small .navwrapper.active {
  top: 58px;
}

.navbar {
  -webkit-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.5);
  align-items: center;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  -webkit-transition: padding 500ms;
  -moz-transition: padding 500ms;
  -ms-transition: padding 500ms;
  -o-transition: padding 500ms;
  transition: padding 500ms;
}

.navbar.height--large {
  padding: 20px 20px;
}

.navbar.height--small {
  padding: 6px 20px;
}

.navbutton {
  cursor: pointer;
  height: 22px;
  position: relative;
  width: 22px;
  z-index: 15;
  -webkit-transition: top 500ms;
  -moz-transition: top 500ms;
  -ms-transition: top 500ms;
  -o-transition: top 500ms;
  transition: top 500ms;
}

/* Mainnavigation */

.navlist {
  -webkit-transition: top 500ms;
  -moz-transition: top 500ms;
  -ms-transition: top 500ms;
  -o-transition: top 500ms;
  transition: top 500ms;
  background: #ffffff;
}

.navlist.active {
  top: 87px;
}

.navlist__item {
  border-bottom: 1px solid $color-primary;
  font-size: 1.11em;
}

.navlist__item:last-of-type {
  border-bottom: 0 none;
}

.navlist__item.active {
  font-weight: 700;
}

.navlist__link {
  color: $color-black !important;
  display: block;
  font-size: 1.11em;
  font-weight: 400;
  text-decoration: none;
  margin: 0;
  padding: 15px;
  position: relative;
}

.navlist__link:hover, .navlist__link:focus {
  text-decoration: none;
  color:$color-primary !important;
}

.navlist__link.active, .navlist__link.trail {
  font-weight: 700;
  text-decoration: none;
  color:$color-primary !important;
}

/* Footernavigation */

.navbar.navbar--footer {
  background: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
  display: block;
}

.navbar--footer .navlist {
  background: transparent;
}

.navbar--footer .navlist__item {
  border: 0 none;
  font-size: 1em;
}

.navbar--footer .navlist__link {
  background: transparent;
  color: #ffffff !important;
  font-size: 1em;
  padding: 0 0 10px 0;
}

.navbar--footer .navlist__link:hover, .navbar--footer .navlist__link:focus {
  text-decoration: underline;
}

.sociallist__item {
  display: inline-block;
}

.footericon {
  color: #ffffff !important;
  font-size: 0;
  line-height: 0;
  text-decoration: none !important;
}

.footericon:before {
  display: block;
  font-size: 40px;
  line-height: 1em;
}
main#main-content {
  margin-top: 110px;
}
@media only screen and (min-width: 768px) {
  .navwrapper.active {
    top: 94px;
  }
  .height--small .navwrapper.active {
    top: 64px;
  }
  .sociallist {
    margin-left: -9px;
  }
}

@media only screen and (max-width: 989px) {
  .navbutton:before {
    color: $color-primary;
    content: "";
    left: -53px;
    position: absolute;
    top: 0;
  }
 
  .row-nav{
    flex-wrap: nowrap;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  

}

@media only screen and (min-width: 990px) {
  main#main-content {
    padding-top: 50px;
  }
  .navwrapper {
    background: #ffffff;
    align-items: center;
    display: flex;
    flex-direction: row;
    left: auto;
    padding: 0;
    position: relative;
    top: auto !important;
    width: auto;
    z-index: 0;
    margin: 0 auto;
  }
  .navbar.height--large {
    padding: 26px 20px;
  }
  .navbar.height--small {
    padding: 6px 20px;
  }
  .navlist {
    flex-grow: 1;
    text-align: right;
    margin-right: 10px;
    margin-bottom: 0;
  }
  .navlist__item {
    display: inline;
    border-bottom: 0 none;
    border-left: 1px solid $color-primary;
  }
  .navlist__item:first-of-type {
    border-left: 0px solid $color-primary;
  }
  .navlist__link {
    display: inline-block;
    padding: 0;
    margin: 0 7px 0 10px;
  }
  .navlist__link::before {
    border-top: 2px solid $color-primary;
    content: "";
    position: absolute;
    top: 30px;
    left: 0;
    width: 100%;
    -webkit-transform: scaleX(0);
    -moz-transform: scaleX(0);
    -ms-transform: scaleX(0);
    -o-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: right center;
    -moz-transform-origin: right center;
    -ms-transform-origin: right center;
    -o-transform-origin: right center;
    transform-origin: right center;
    -webkit-transition: -webkit-transform .2s cubic-bezier(.215, .61, .355, 1);
    -moz-transition: -webkit-transform .2s cubic-bezier(.215, .61, .355, 1);
    -ms-transition: -webkit-transform .2s cubic-bezier(.215, .61, .355, 1);
    -o-transition: -webkit-transform .2s cubic-bezier(.215, .61, .355, 1);
    transition: -webkit-transform .2s cubic-bezier(.215, .61, .355, 1);
  }
  .navlist__link:hover::before {
    -webkit-transform: scaleX(1);
    -moz-transform: scaleX(1);
    -ms-transform: scaleX(1);
    -o-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -ms-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
  }
  .footericon:before {
    font-size: 60px;
  }
  .sociallist {
    margin-left: -15px;
  }
}

@media only screen and (min-width: 1300px) {
  .navlist {
    margin-right: 30px;
    margin-bottom: 0;
  }
}

@media only screen and (min-width: 1400px) {
  .navlist {
    margin-right: 60px;
    margin-bottom: 0;
  }
}

.line {
  background-color: $color-primary;
  display: block;
  height: 2px;
  position: absolute;
  width: 22px;
}

.line--top {
  left: 0;
  top: 4px;
}

.line--middle {
  left: 0;
  top: 11px
}

.line--bottom {
  left: 0px;
  top: 18px;
}

/* show Menu */

.navbutton.active .line--top {
  -webkit-animation: line-top-animation 400ms 0s 1 forwards;
  -moz-animation: line-top-animation 400ms 0s 1 forwards;
  -o-animation: line-top-animation 400ms 0s 1 forwards;
  animation: line-top-animation 400ms 0s 1 forwards;
}

@-webkit-keyframes line-top-animation {
  from {
    top: 4px;
    transform: rotate(0deg);
  }
  to {
    top: 11px;
    transform: rotate(45deg);
  }
}

@-moz-keyframes line-top-animation {
  from {
    top: 4px;
    transform: rotate(0deg);
  }
  to {
    top: 11px;
    transform: rotate(45deg);
  }
}

@-o-keyframes line-top-animation {
  from {
    top: 4px;
    transform: rotate(0deg);
  }
  to {
    top: 11px;
    transform: rotate(45deg);
  }
}

@keyframes line-top-animation {
  from {
    top: 4px;
    transform: rotate(0deg);
  }
  to {
    top: 11px;
    transform: rotate(45deg);
  }
}

.navbutton.active .line--bottom {
  -webkit-animation: line-bottom-animation 400ms 0s 1 forwards;
  -moz-animation: line-bottom-animation 400ms 0s 1 forwards;
  -o-animation: line-bottom-animation 400ms 0s 1 forwards;
  animation: line-bottom-animation 400ms 0s 1 forwards;
}

@-webkit-keyframes line-bottom-animation {
  from {
    top: 18px;
    transform: rotate(0deg);
  }
  to {
    top: 11px;
    transform: rotate(-45deg);
  }
}

@-moz-keyframes line-bottom-animation {
  from {
    top: 18px;
    transform: rotate(0deg);
  }
  to {
    top: 11px;
    transform: rotate(-45deg);
  }
}

@-o-keyframes line-bottom-animation {
  from {
    top: 18px;
    transform: rotate(0deg);
  }
  to {
    top: 11px;
    transform: rotate(-45deg);
  }
}

@keyframes line-bottom-animation {
  from {
    top: 18px;
    transform: rotate(0deg);
  }
  to {
    top: 11px;
    transform: rotate(-45deg);
  }
}

.navbutton.active .line--middle {
  -webkit-animation: line-middle-animation 400ms 0s 1 forwards;
  -moz-animation: line-middle-animation 400ms 0s 1 forwards;
  -o-animation: line-middle-animation 400ms 0s 1 forwards;
  animation: line-middle-animation 400ms 0s 1 forwards;
}

@-webkit-keyframes line-middle-animation {
  from {
    width: 22px;
  }
  to {
    width: 0px;
  }
}

@-moz-keyframes line-middle-animation {
  from {
    width: 22px;
  }
  to {
    width: 0px;
  }
}

@-o-keyframes line-middle-animation {
  from {
    width: 22px;
  }
  to {
    width: 0px;
  }
}

@keyframes line-middle-animation {
  from {
    width: 22px;
  }
  to {
    width: 0px;
  }
}

/* hide Menu */

.navbutton .line--top {
  -webkit-animation: line-top-animation-back 400ms 0s 1 forwards;
  -moz-animation: line-top-animation-back 400ms 0s 1 forwards;
  -o-animation: line-top-animation-back 400ms 0s 1 forwards;
  animation: line-top-animation-back 400ms 0s 1 forwards;
}

@-webkit-keyframes line-top-animation-back {
  from {
    top: 11px;
    transform: rotate(45deg);
  }
  to {
    top: 4px;
    transform: rotate(0deg);
  }
}

@-moz-keyframes line-top-animation-back {
  from {
    top: 11px;
    transform: rotate(45deg);
  }
  to {
    top: 4px;
    transform: rotate(0deg);
  }
}

@-o-keyframes line-top-animation-back {
  from {
    top: 11px;
    transform: rotate(45deg);
  }
  to {
    top: 4px;
    transform: rotate(0deg);
  }
}

@keyframes line-top-animation-back {
  from {
    top: 11px;
    transform: rotate(45deg);
  }
  to {
    top: 4px;
    transform: rotate(0deg);
  }
}

.navbutton .line--bottom {
  -webkit-animation: line-bottom-animation-back 400ms 0s 1 forwards;
  -moz-animation: line-bottom-animation-back 400ms 0s 1 forwards;
  -o-animation: line-bottom-animation-back 400ms 0s 1 forwards;
  animation: line-bottom-animation-back 400ms 0s 1 forwards;
}

@-webkit-keyframes line-bottom-animation-back {
  from {
    top: 11px;
    transform: rotate(-45deg);
  }
  to {
    top: 18px;
    transform: rotate(0deg);
  }
}

@-moz-keyframes line-bottom-animation-back {
  from {
    top: 11px;
    transform: rotate(-45deg);
  }
  to {
    top: 18px;
    transform: rotate(0deg);
  }
}

@-o-keyframes line-bottom-animation-back {
  from {
    top: 11px;
    transform: rotate(-45deg);
  }
  to {
    top: 18px;
    transform: rotate(0deg);
  }
}

@keyframes line-bottom-animation-back {
  from {
    top: 11px;
    transform: rotate(-45deg);
  }
  to {
    top: 18px;
    transform: rotate(0deg);
  }
}

.navbutton .line--middle {
  -webkit-animation: line-middle-animation-back 400ms 0s 1 forwards;
  -moz-animation: line-center-animation-back 400ms 0s 1 forwards;
  -o-animation: line-center-animation-back 400ms 0s 1 forwards;
  animation: line-center-animation-back 400ms 0s 1 forwards;
}

@-webkit-keyframes line-middle-animation-back {
  from {
    width: 0px;
  }
  to {
    width: 22px;
  }
}

@-moz-keyframes line-middle-animation-back {
  from {
    width: 0px;
  }
  to {
    width: 22px;
  }
}

@-o-keyframes line-middle-animation-back {
  from {
    width: 0px;
  }
  to {
    width: 22px;
  }
}

@keyframes line-middle-animation-back {
  from {
    width: 0px;
  }
  to {
    width: 22px;
  }
}
