.logo {
  display: flex;
  justify-content: center;
  margin: 0;
  width: 300px;

 
  img {
    margin-bottom: 0;
  }
}

.logo--footer {
  margin-bottom: 2.5rem;
  
}

@media only screen and (min-width: 1200px) {
  .logo {
    width: 300px;
  }
}
@media (max-width: 770px) {
  .logo--footer {
    margin-bottom: 1.5rem;
    display: flex;
  }
  .logo{
    width: 195px;
    padding: 10px 40px 0px;
  }
}
